import React, { useEffect, useState } from "react";
import ModulesLayout from "../common/ModulesLayout";
import { makeStyles } from "@material-ui/core/styles";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Payment from "../components/Payment";
import Success from "../components/Success";
import { useCallback } from "react";
import { fetchCards } from "services/sync";
import { processCharge } from "../services/clone";
import { toast } from "helpers/apiRequests";
import danger from "../../../assets/icons/danger.svg";
import caution from "../../../assets/icons/caution.svg";
import checked from "../../../assets/icons/checked.svg";
import OrgCheckoutV2 from "../components/OrgCheckoutV2";
import AppCheckoutV2 from "../components/AppCheckoutV2";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    width: "100%",
    margin: "10% 15%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    // padding: "32px 5%",
    width: "100%",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#3B3B3B",
    // marginBottom: "35px",
    display: "flex",
    alignItems: "center",
    gap: "19px",
    cursor: "pointer",
  },
  text: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    maxWidth: "719px",
  },
  border: {
    width: "100%",
    height: "1px",
    background: "#E1E1E1",
    margin: "24px 0 26px",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
  },
  inputBox: {
    height: "44px",
    width: "497px",
    border: "0.5px solid #9E9E9E",
    borderRadius: "4px 0 0 4px",
    padding: "0px 16px !important",
    backgroundColor: "#fff",
  },
  btn: {
    height: "44px",
    width: "48px",
    minWidth: "auto",
    color: "#fff !important",
    backgroundColor: "#F0B429",
    borderRadius: "0 4px 4px 0",
    textTransform: "capitalize",
  },
  th: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
    padding: "21px 0 21px 32px",
  },
  td: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    padding: "21px 0 21px 32px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
  },
  center: {
    textAlign: "center",
  },
  tableHeaderRow: {
    alignItems: "center",
    backgroundColor: "#F7F7F7",
  },
  tableBodyRow: {
    alignItems: "center",
    backgroundColor: "#fff",
    border: "none",
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    color: "#fff !important",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "8px 32px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
  },
  opacity: {
    opacity: "0.5",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginTop: "54px",
  },
  paginationDataBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    height: "32px",
    width: "32px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    border: "1px solid #CFCFCF",
    color: "#3B3B3B",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
  },
  activePaginationData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    borderRadius: "4px",
    padding: "0px",
    backgroundColor: "#F0B429",
    border: "1px solid #F0B429",
    color: "#fff !important",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  body: {
    width: "70vw",
  },
  headerDIv: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  infoDiv: {
    display: "flex",
    alignItems: "center",
    marginLeft: "2%",
    backgroundColor: "#FFFAEB",
    padding: "5px",
    marginTop: "10px",
  },
  infoText: {
    fontSize: "14px",
    color: "#B44D12",
    marginLeft: "5px",
  },
  info: {
    fontWeight: "semibold",
    marginTop: "2%",
    marginLeft: "4%",
  },
  infoChecked: {
    fontWeight: "semibold",
    marginLeft: "7px",
    marginBottom: "0px",
  },
}));

const Cart = ({
  module,
  data,
  appData,
  sourceData,
  setData,
  responseData,
  submitData,
  showActivities,
  setStep,
  loading: externalLoading,
  orgInfoData,
  clonePrice,
}) => {
  const classes = useStyles();
  const [activeScreen, setActiveScreen] = useState("checkout");
  const [selectedCard, setSelectedCard] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [trackingId, setTrackingId] = useState("");
  const { orgInfo } = orgInfoData;

  const handleCheckout = async () => {
    setLoading(true);

    const cardData = {
      card_id: selectedCard,
      payload: responseData.payload,
    };

    try {
      const response = await processCharge(cardData);
      if (response.code === 200) {
        toast.notify(response.message, { type: "success" });
        setActiveScreen("success");
        setTrackingId(response.data._id);
      }

      if (response.code === 400) {
        toast.notify(response.message, { type: "error" });
      }

      if (response.code === 422) {
        toast.notify(response.message, { type: "error" });
        return;
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (module === "work-to-org") {
  //     setActiveScreen("payment");
  //   }
  // }, [module]);

  const loadCards = useCallback(() => {
    async function cards() {
      await fetchCards()
        .then((theCards) => {
          const cards = theCards.data;
          const defaultCard = cards.find((item) => item.make_default === 1);
          setSelectedCard(defaultCard.id);
          setCardList(cards);
        })
        .catch((e) => {
          // toast.notify(e?.message, {
          //   type: "error",
          // });
        });
    }
    cards();
  }, []);

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const paymentBack = () => {
    if (module === "org-to-org" || module === "app-to-work") {
      setActiveScreen("checkout");
    }
  };

  const cartBack = () => {
    setStep(1);
  };


  return (
    <ModulesLayout>
      <div className={classes.layoutContainer}>
        <div>
          <div className={classes.header}>
            {activeScreen === "checkout" && (
              <div className={classes.headerDIv}>
                <h4 className={classes.title}>
                  <IoArrowBackCircleOutline
                    onClick={cartBack}
                    fontSize="35px"
                  />
                  {module === "org-to-org"
                    ? "Select spaces and apps"
                    : "Select apps"}
                </h4>
                {orgInfoData?.isStructureChecked?.value === true ? null : (
                  <div className={classes.infoDiv}>
                    <img
                      className={classes.infoIcon}
                      src={danger}
                      alt="dangerIcon"
                    />
                    <h5 className={classes.infoText}>
                      Note that items and files are charged at ${clonePrice}{" "}
                      cent per record
                    </h5>
                  </div>
                )}
              </div>
            )}

            {activeScreen === "payment" && (
              <h4 onClick={paymentBack} className={classes.title}>
                <IoArrowBackCircleOutline fontSize="35px" />
                Payment
              </h4>
            )}

            {activeScreen === "checkout" ? (
              <p className={classes.info}>{orgInfo}</p>
            ) : null}
          </div>
          <div className={classes.body}>
            {activeScreen === "checkout" &&
              ((module === "org-to-org" && (
                <OrgCheckoutV2
                  {...{
                    setActiveScreen,
                    submitData,
                    data,
                    appData,
                    setData,
                    sourceData,
                    responseData,
                    clonePrice,
                    isStructure: orgInfoData.isStructureChecked.value,
                  }}
                  loading={externalLoading}
                />
              )) ||
                (module === "app-to-work" && (
                  <AppCheckoutV2
                    {...{
                      setActiveScreen,
                      submitData,
                      data,
                      setData,
                      sourceData,
                      responseData,
                    }}
                    loading={externalLoading}
                  />
                )))}
            {activeScreen === "payment" && (
              <Payment
                {...{
                  setActiveScreen,
                  cardList,
                  responseData,
                  handleCheckout,
                  setSelectedCard,
                  loading,
                }}
              />
            )}
            {activeScreen === "success" && (
              <Success {...{ showActivities, trackingId }} />
            )}
          </div>
        </div>
        {activeScreen === "checkout" && module === "org-to-org" ? (
          <div
            style={{
              width: "100%",
              marginBottom: "5%",
            }}
          >
            {orgInfoData.isStructureChecked.value === true ? (
              <div className="d-flex align-items-center">
                <img width="25px" src={checked} alt="" />
                <p className={classes.infoChecked}>
                  Organisation Structure will be cloned
                </p>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <img width="25px" src={caution} alt="" />
                <p className={classes.infoChecked}>
                  Organisation Structure won't be cloned
                </p>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </ModulesLayout>
  );
};

export default Cart;
