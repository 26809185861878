import { takeLatest, call } from "redux-saga/effects";
import api, { cloneRequest } from "../../services";
import {
  GET_SOURCE_ORGANISATIONS,
  GET_SOURCE_SPACES,
  GET_SOURCE_APPS,
  GET_DEST_ORGANISATIONS,
  GET_DEST_SPACES
} from "../constants/clone";
import safeSaga from "../../helpers/safeSaga";
import { successHandler } from "../../helpers/apiRequests";
import { convertParamsToString } from "../../helpers/urlParser";
// import history from "../../helpers/history";

function* getSourceOrganisations() {
  const response = yield call([api, "get"], cloneRequest.ORGANIZATIONS);
  yield call(successHandler, response, GET_SOURCE_ORGANISATIONS.success);
}

function* getSourceSpaces({ payload }) {
  const url = convertParamsToString(cloneRequest.SPACES, payload);

  const response = yield call([api, "get"], url);

  yield call(successHandler, response, GET_SOURCE_SPACES.success);
}

function* getSourceApps({ payload }) {
  const url = convertParamsToString(cloneRequest.APPS, payload);

  const response = yield call([api, "get"], url);

  yield call(successHandler, response, GET_SOURCE_APPS.success);
}

function* getDestOrganisations() {
  const response = yield call([api, "get"], cloneRequest.ORGANIZATIONS);

  yield call(successHandler, response, GET_DEST_ORGANISATIONS.success);
}

function* getDestSpaces({ payload }) {
  const url = convertParamsToString(cloneRequest.SPACES, payload);

  const response = yield call([api, "get"], url);

  yield call(successHandler, response, GET_DEST_SPACES.success);
}

export default function* cloneSaga() {
  yield takeLatest(GET_SOURCE_ORGANISATIONS.request, safeSaga(getSourceOrganisations, GET_SOURCE_ORGANISATIONS.error));
  yield takeLatest(GET_SOURCE_SPACES.request, safeSaga(getSourceSpaces, GET_SOURCE_SPACES.error));
  yield takeLatest(GET_SOURCE_APPS.request, safeSaga(getSourceApps, GET_SOURCE_APPS.error));
  yield takeLatest(GET_DEST_ORGANISATIONS.request, safeSaga(getDestOrganisations, GET_DEST_ORGANISATIONS.error));
  yield takeLatest(GET_DEST_SPACES.request, safeSaga(getDestSpaces, GET_DEST_SPACES.error));
}