import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import ModulesLayout from "../common/ModulesLayout";
import OrgToOrgModal from "../modals/OrgToOrgModal";
import { makeStyles } from "@material-ui/core/styles";
import transferFiles from "../../../assets/images/Transfer-files-amico.svg";
// import SpaceList from "../components/SpaceList";
import { useSelector } from "react-redux";
import { toast } from "helpers/apiRequests";
import { processOrg } from "../services/clone";
import Cart from "./Cart";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackButton from "components/atoms/BackButton";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    margin: "111px auto",
    width: "100vw",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  btn: {
    height: "44px",
    width: "234px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#3B3B3B",
    marginBottom: "10px",
  },
  title_name: {
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#3B3B3B",
    marginBottom: "10px",
    marginTop: "15px",
  },
  text: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "25px",
    marginBottom: "42px",
    width: "50%",
  },
  logBtn: {
    height: "44px",
    width: "170px",
    backgroundColor: "#FFF3C4",
    borderRadius: "3px",
    textTransform: "capitalize",
    color: "#DE911D !important",
    fontSize: "16px",
    fontWeight: "600",
    position: "absolute",
    top: "111px",
    right: "71px",
  },

  "@media screen and (max-width: 600px)": {
    logBtn: {
      top: "80px",
      right: "20px",
      height: "36px",
      width: "140px",
      fontSize: "14px",
    },
    title: {
      fontSize: "18px",
    },
    title_name: {
      fontSize: "18px",
    },
    text: {
      fontSize: "13px",
      width: "90%",
      marginTop: "3%",
      marginBottom: "22px",
    },
    layoutContainer: {
      margin: "60px auto",
    },
    logBtnMobile: {
      height: "44px",
      width: "90%",
      backgroundColor: "#FFF3C4",
      borderRadius: "3px",
      textTransform: "capitalize",
      color: "#DE911D !important",
      fontSize: "16px",
      fontWeight: "600",
      marginTop: "5%",
    },
    btn: {
      width: "90%",
    },
  },
}));

const initialState = {
  source_org_id: "",
  destination_org_id: "",
  source_space_ids: [],
  source_app_ids: [],
};

const OrgToOrg = ({ showActivities }) => {
  const classes = useStyles();
  const { sourceSpaces } = useSelector((state) => state.clone);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...initialState });
  const [responseData, setResponseData] = useState(null);
  const [orgNameInfo, setOrgNameInfo] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [clonePrice, setClonePrice] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const goToNext = (orgInfoData) => {
    if (!data.source_org_id || data.source_org_id === "") {
      toast.notify("Please select the source organisation", { type: "error" });
    }

    if (!data.destination_org_id || data.destination_org_id === "") {
      toast.notify("Please select the destination organisation", {
        type: "error",
      });
    }

    if (data.source_org_id && data.destination_org_id) {
      setOpen(false);
      setStep(2);
    }

    setOrgNameInfo(orgInfoData);
  };

  const submitData = useCallback(async () => {
    if (data.source_space_ids && data.source_space_ids.length < 1) {
      toast.notify("Please select the spaces you want to copy", {
        type: "error",
      });
    } else {
      setLoading(true);
      try {
        const response = await processOrg(data);
        if (response.success) {
          toast.notify(response.message, { type: "success" });
          setResponseData(response.data);
          return true;
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        setLoading(false);
      }
      return false;
    }
  }, [data]);

  return (
    <ModulesLayout>
      {step === 1 && (
        <div className={classes.layoutContainer}>
          {isMobile ? (
            <div style={{ width: "90%" }}>
              <BackButton />
            </div>
          ) : null}

          {isMobile ? null : (
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              onClick={showActivities}
              className={classes.logBtn}
            >
              Activity Log
            </Button>
          )}

          <img src={transferFiles} width="306" alt="" />
          <h4 className={classes.title}>
            Copy Data from an Organization to another Organization
          </h4>
          <p className={classes.text}>
            Copy all existing Spaces and applications from a chosen organization
            to another designated organization, effectively replicating the
            entire setup. This process ensures a comprehensive transfer of
            spaces, apps, and settings, streamlining the transition and
            maintaining operational continuity.
          </p>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
            onClick={() => setOpen(true)}
          >
            Copy Data
          </Button>

          {isMobile ? (
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              className={classes.logBtnMobile}
              onClick={() => setOpen(true)}
            >
              Activity Log
            </Button>
          ) : null}
        </div>
      )}
      {/* {step === 2 && (
        <SpaceList
          spaces={sourceSpaces}
          {...{ setStep, setData, submitData, loading, data }}
        />
      )} */}
      {step === 2 && (
        <Cart
          module="org-to-org"
          data={data.source_space_ids}
          appData={data.source_app_ids}
          sourceData={sourceSpaces}
          {...{
            responseData,
            setData,
            submitData,
            showActivities,
            loading,
            setStep,
            orgInfoData: orgNameInfo,
            clonePrice,
          }}
        />
      )}

      <OrgToOrgModal
        {...{ data, setClonePrice, setData, open, handleClose, goToNext }}
      />
    </ModulesLayout>
  );
};

export default OrgToOrg;
