import { TextField } from '@material-ui/core';
import React, { useState } from 'react'

const MappersInput = ({ field, action }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      {!showInput && <p style={{ 
        padding: '6px 3px 5px',
        fontSize: '16px',
        borderBottom: '1px solid #000',
        marginBottom: '1px',
        cursor: 'text'
       }} 
        onClick={() => setShowInput(true)}
      >{field.label}</p>}
      {showInput && <TextField
        autoFocus
        data-id={field.field_id}
        data-name={field.label}
        defaultValue={field.label}
        required
        onChange={(e) => action(e)}
        onBlur={() => setShowInput(false)}
        id="name"
        label=""
        type="text"
        fullWidth
        style={{ fontSize: '11px' }}
        variant="standard"
      />}
    </>
  )
}

export default MappersInput