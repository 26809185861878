import React, { useEffect, useState } from "react";
import PdcLayout from "./_PdcLayout";
import { makeStyles } from "@material-ui/core/styles";
import { PdcSelect } from "../common/PdcSelect";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import useCloneDetails from "../hooks/useCloneDetails";
import { useCallback } from "react";
import { useMemo } from "react";
import { getClonePrice } from "services/sync";

const useStyles = makeStyles((theme) => ({
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "40px",
  },
  label: {
    color: "#515151",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  text_field: {
    background: "#fff",
    width: "100%",
    maxWidth: "380px",
    height: "40px",
    maxHeight: "40px",
  },
  btn: {
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "12px 32px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
  },
  btnCancel: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    padding: "0px",
    background: "transparent",
    border: "none",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "78px",
    gap: "32px",
  },
  structure: {
    display: "flex",
    alignItems: "center",
    marginTop: "-2%",
    marginBottom: "6%",
    justifyContent: "space-between",
  },
  structureInput: {
    width: "20px",
    appearance: "auto",
    marginRight: "2%",
  },
  structureText: {
    fontSize: "16px",
    fontWeight: "500",
  },

  "@media screen and (max-width: 600px)": {
    inputGroup: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    label: {
      marginBottom: "3%",
    },
  },
}));

const OrgToOrgModal = ({
  setData,
  open,
  handleClose,
  goToNext,
  setClonePrice,
}) => {
  const classes = useStyles();
  const [selectedSourceOrgValue, setSelectedSourceOrgValue] = useState({});
  const [selectedDestOrgValue, setSelectedDestOrgValue] = useState({});
  const [orgInfo, setOrgInfo] = useState("");
  const { sourceOrganisations, destOrganisations } = useSelector(
    (state) => state.clone
  );
  const [isStructureChecked, setIsStructureChecked] = useState(false);

  const {
    sourceOrgsData,
    destOrgsData,
    selectedDestOrg,
    selectedSourceOrg,
    handleSourceOrgs,
    handleDestOrgs,
  } = useCloneDetails();

  const updateSelectedSourceOrgValue = useCallback(
    (value) => {
      setSelectedSourceOrgValue(value);
      setData((prev) => ({ ...prev, source_org_id: value?.value }));
    },
    [setData]
  );

  const updateSelectedDestOrgValue = useCallback(
    (value) => {
      setSelectedDestOrgValue(value);
      setData((prev) => ({ ...prev, destination_org_id: value?.value }));
    },
    [setData]
  );

  const handleCheckboxChange = useCallback(
    (value) => {
      setIsStructureChecked(value);
      setData((prev) => ({ ...prev, structure_only: value.value }));
    },
    [setData, setIsStructureChecked]
  );

  useEffect(() => {
    setSelectedSourceOrgValue({});
    setSelectedDestOrgValue({});
    setOrgInfo("");
  }, [open]);

  useEffect(() => {
    // Update dynamic text based on selected organizations
    setOrgInfo(() =>
      selectedSourceOrgValue && selectedDestOrgValue ? (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <p style={{ marginRight: "5%" }}>
            <span style={{ fontWeight: "600" }}>Source Org : </span>{" "}
            {selectedSourceOrgValue.label}
          </p>
          <p>
            <span style={{ fontWeight: "600" }}>Destination Org : </span>
            {selectedDestOrgValue.label}
          </p>
        </div>
      ) : (
        ""
      )
    );
  }, [selectedSourceOrgValue, selectedDestOrgValue]);

  useMemo(() => {
    const val =
      sourceOrgsData?.find((i) => i.value === selectedSourceOrg) ?? null;
    updateSelectedSourceOrgValue(val);
  }, [selectedSourceOrg, sourceOrgsData, updateSelectedSourceOrgValue]);

  useMemo(() => {
    const val = destOrgsData?.find((i) => i.value === selectedDestOrg) ?? null;
    updateSelectedDestOrgValue(val);
  }, [selectedDestOrg, destOrgsData, updateSelectedDestOrgValue]);

  const handleGoToNext = () => {
    const orgInfoData = { orgInfo, isStructureChecked };
    goToNext(orgInfoData);
    getPrice();
  };

  const getPrice = async () => {
    try {
      const response = await getClonePrice(isStructureChecked?.value);
      setClonePrice(response?.data?.cost_of_clone);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PdcLayout
      open={open}
      handleClose={handleClose}
      message="Select source organisation and destination organisation"
    >
      <div className={classes.structure}>
        <h5 className={classes.label}>Clone type</h5>
        <PdcSelect
          required
          placeholder="Select option"
          className={classes.text_field}
          name="yes_or_no"
          options={[
            { label: "Full transfer (Structure and Data)", value: false },
            { label: "Structure only", value: true },
          ]}
          onChange={(val) => handleCheckboxChange(val)}
          style={{ fontSize: "14px" }}
          value={isStructureChecked}
        />
      </div>
      <div>
        <div className={classes.inputGroup}>
          <label htmlFor="sourceOrg" className={classes.label}>
            Source Organization
          </label>
          <PdcSelect
            required
            placeholder="Select Source Organisation"
            className={classes.text_field}
            name="source_organization"
            options={sourceOrgsData}
            onChange={(val) => handleSourceOrgs({ target: val })}
            style={{ fontSize: "14px" }}
            isDisabled={!sourceOrgsData?.length > 0}
            isLoading={sourceOrganisations.processing}
            value={selectedSourceOrgValue}
          />
        </div>

        <div className={classes.inputGroup}>
          <label htmlFor="sourceOrg" className={classes.label}>
            Destination Organization
          </label>
          <PdcSelect
            required
            placeholder="Select Destination Organisation"
            className={classes.text_field}
            name="destination_organization"
            options={destOrgsData}
            onChange={(val) => handleDestOrgs({ target: val })}
            style={{ fontSize: "14px" }}
            isDisabled={!destOrgsData?.length > 0}
            isLoading={destOrganisations.processing}
            value={selectedDestOrgValue}
          />
        </div>

        <div className={classes.btnGroup}>
          <button
            type="button"
            className={classes.btnCancel}
            onClick={handleClose}
          >
            Cancel
          </button>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
            onClick={handleGoToNext}
            disabled={!isStructureChecked}
          >
            Copy
          </Button>
        </div>
      </div>
    </PdcLayout>
  );
};

export default OrgToOrgModal;
