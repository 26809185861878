import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSourceOrgs, getSourceSpaces, getSourceApps, getDestOrgs, getDestSpaces } from '../../../redux/actions/clone';

const useCloneDetails = () => {
  const dispatch = useDispatch();

  const sourceOrganisations = useSelector((state) => state.clone.sourceOrganisations)
  const sourceSpaces = useSelector((state) => state.clone.sourceSpaces)
  const sourceApps = useSelector((state) => state.clone.sourceApps)
  const destOrganisations = useSelector((state) => state.clone.destOrganisations)
  const destSpaces = useSelector((state) => state.clone.destSpaces)

  const [sourceOrgsData, setSourceOrgsData] = useState([])
  const [sourceSpacesData, setSourceSpacesData] = useState([])
  const [sourceAppsData, setSourceAppsData] = useState([])
  const [destOrgsData, setDestOrgsData] = useState([])
  const [destSpacesData, setDestSpacesData] = useState([])

  const [selectedSourceOrg, setSelectedSourceOrg] = useState('')
  const [selectedSourceSpace, setSelectedSourceSpace] = useState('')
  const [selectedSourceApp, setSelectedSourceApp] = useState([])
  const [selectedDestOrg, setSelectedDestOrg] = useState('')
  const [selectedDestSpace, setSelectedDestSpace] = useState('')

  // get source orgs 
  useEffect(() => {
    if (!sourceOrganisations.processed && !sourceOrganisations.success) {
      dispatch(getSourceOrgs());
    }

  }, [dispatch, sourceOrganisations.processed, sourceOrganisations.success]);

  useEffect(() => {
    if (sourceOrganisations.success) {
      setSourceOrgsData(
        sourceOrganisations.data.map((item) => {
          return { value: item.org_id, label: `${item.org_name} - ${item.org_id}` };
        })
      );
    }
  }, [sourceOrganisations.data, sourceOrganisations.success]);
  // end of get source orgs 

  useEffect(() => {
    if (!destOrganisations.processed && !destOrganisations.success) {
      dispatch(getDestOrgs());
    }

  }, [dispatch, destOrganisations.processed, destOrganisations.success]);

  // get destination orgs
  useEffect(() => {
    if (destOrganisations.success) {
      setDestOrgsData(
        destOrganisations.data.map((item) => {
          return { value: item.org_id, label: `${item.org_name} - ${item.org_id}` };
        })
      );
    }
  }, [destOrganisations.data, destOrganisations.success]);
  // end of get destination orgs

  // get source spaces
  useEffect(() => {
    if (sourceSpaces.success) {
      setSourceSpacesData(
        sourceSpaces.data.map((item) => {
          return { value: item.space_id, label: `${item.name} - ${item.space_id}` };
        })
      );
    }
  }, [sourceSpaces.data, sourceSpaces.success]);
  // end of get source spaces

  // get dest spaces
  useEffect(() => {
    if (destSpaces.success) {
      setDestSpacesData(
        destSpaces.data.map((item) => {
          return { value: item.space_id, label: `${item.name} - ${item.space_id}` };
        })
      );
    }
  }, [destSpaces.data, destSpaces.success]);
  // end of get dest spaces

  // get source app
  useEffect(() => {
    if (sourceApps.success) {
      setSourceAppsData(
        sourceApps.data.map((item) => {
          return item;
          // return { value: item.org_id, label: `${item.name} - ${item.org_id}` };
        })
      );
    }
  }, [sourceApps.data, sourceApps.success]);
  // end of get source app

  const handleSourceOrgs = (event) => {
    setSelectedSourceSpace("")
    setSelectedSourceApp("")

    setSelectedSourceOrg(event.target.value);
    dispatch(getSourceSpaces({ org_id: event.target.value }));
  };

  const handleSourceSpaces = (event) => {
    setSelectedSourceApp("")
 
    setSelectedSourceSpace(event.target.value);
    dispatch(getSourceApps({ space_id: event.target.value, org_id: selectedSourceOrg }));
  };

  const handleSourceApp = (event) => {
    setSelectedSourceApp(event.target.value);
  };

  const handleDestOrgs = (event) => {
    setSelectedDestSpace("")

    setSelectedDestOrg(event.target.value);
    dispatch(getDestSpaces({ org_id: event.target.value }));
  };

  const handleDestSpaces = (event) => {
    setSelectedDestSpace(event.target.value);
  };

  return { 
    sourceOrgsData,
    sourceSpacesData,
    sourceAppsData,
    destOrgsData,
    destSpacesData,
    selectedSourceOrg,
    selectedSourceSpace,
    selectedSourceApp,
    selectedDestOrg,
    selectedDestSpace,
    handleSourceOrgs,
    handleSourceSpaces,
    handleSourceApp,
    handleDestOrgs,
    handleDestSpaces 
  };
};

export default useCloneDetails;
