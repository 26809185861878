import React from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import OrphanEmail from "../OrphanEmail";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const MapperModal = (props) => {
    const { openModal, handleClose, changeOrgId, items, mappedData, apps } = props;
    return (
      <div>
          <Dialog
              open={openModal}
              onClose={handleClose}
              disableEscapeKeyDown={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
          >
              <DialogTitle>
              <Typography id="modal-modal-title" variant="h6" component="p">
                  Map Orphan Email
              </Typography>
              </DialogTitle>
              <DialogActions style={{position: "absolute", left: "93%"}}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
              <DialogContent>
                <Box style={{ padding: "10px", width: "800px", minHeight: "800px"}}>
                  <OrphanEmail
                    changeOrgId={changeOrgId}
                    items={items}
                    mappedData={mappedData}
                    apps={apps}
                    handleClose={handleClose}
                  />
                </Box>
              </DialogContent>
          </Dialog>
      </div>
    );
};

export default MapperModal;
