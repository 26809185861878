/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../index.css";
import Logo from "../../../assets/images/logosync.svg";
import bg from "../../../assets/images/authbg.png";
import { Link, useHistory } from "react-router-dom";
import { podioAuthUser } from "../../../services/auth";
import { setUser } from "../../../redux/actions/user";
import { connect } from "react-redux";
import Asterisk from "../../../components/Common/Asterisk";
import { useDispatch } from "react-redux";
import { CircularProgress, Button } from "@material-ui/core";
import cuate from "../../../assets/images/cuate.svg";

const Authorizepass = ({ setUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [userData, setUserData] = useState({
    name: "",
    existing_user: false,
    email: "",
    password: null,
  });
  const [theData, setTheData] = useState(null);

  useEffect(() => {
    (async () => {
      setLoadingPage(true);
      const data = JSON.parse(localStorage.getItem("podio_access_object"));

      if (data === undefined || data?.refresh_token === undefined) {
        history.push("/login");
        return;
      }
      setTheData(data);
      //Validate user
      try {
        const object = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          ref_id: data.user_id,
        };
        let response = await podioAuthUser(object)
        if (response?.data && response?.data?.access_token) {
          localStorage.setItem("chosen_token", response.data.access_token);
          const team_member_uuid = response.data?.team_member?.uuid;
          if (team_member_uuid) {
            localStorage.setItem('team_member_uuid', response.data?.team_member?.uuid);
          }
          dispatch(setUser(response.data));
          localStorage.removeItem("podio_access_object");
          setLoadingPage(false);
          setLoading(false);
          return;
        }
        setUserData(response);
      } catch (error) {
      }
      setLoadingPage(false);
      setLoading(false);
    })();
  }, [dispatch, history, setUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const hidePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }

    setPasswordType("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { password } = data;
    const object = {
      email: userData.email,
      password,
      access_token: theData.access_token,
      refresh_token: theData.refresh_token,
      ref_id: theData.user_id,
    };

    try {
      let response = await podioAuthUser(object);
      if (response) {
        if (response.data) {
          localStorage.setItem("chosen_token", response.data.access_token);
          setUser(response.data);
          localStorage.removeItem("podio_access_object");
        }
      }
    } catch (x) {
      setLoading(false);
    }
  };

  if (loadingPage) {
    return (
      <div className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="65px"
            stroke="#F0B429"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="auth">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div
            className="auth__sidebar"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <div className="layer">
              <div className="custom__container__80">
                <Link className="link__text" to="/">
                  <img src={Logo} alt="logo" />
                </Link>
                <h1 className="title">
                  Welcome to <br /> ThatApp!
                </h1>
                <p className="description">
                  It is our job to ensure that you are never lost in the clouds.
                  Gain a refreshing perspective of your business processes and
                  strategic initiatives through the eyes of our skilled workflow
                  architects and system integrators.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="auth__component">
            <div className="custom__container__80">
              <div className="text-right">
                <p className="route__text">
                  Do not have an account?
                  <Link className="link__text" to="/register">
                    {"  "}Sign up
                  </Link>
                </p>
              </div>
              {/* <div className="auth__form__container mt-5">
                    <h1>
                      Hi {userData.name}, <br />
                      {userData.existing_user
                        ? "Welcome back"
                        : "Welcome to ThatApp"}
                    </h1>
                    <p>
                      {userData.existing_user && userData.password
                        ? "Kindly provide your password to continue."
                        : "To continue, kindly create a password."}
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label>
                            Password <Asterisk />
                          </label>
                          <input
                            className="form-control"
                            type={passwordType}
                            name="password"
                            required
                            value={data.password}
                            onChange={handleChange}
                          />
                          <i
                            className={`${passwordType === "password"
                              ? "far fa-eye"
                              : "far fa-eye-slash"
                              } field-icon2`}
                            onClick={hidePassword}
                          ></i>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                        color="primary"
                      >
                        {loading && <CircularProgress className="mr-1" size={20} />}{" "}
                        Submit
                      </Button>
                    </form>
                  </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div className="mt-5">
                  <img
                    src={cuate}
                    alt=""
                    style={{ width: "100%", marginBottom: "1rem" }}
                  />
                  <p>Unable to authenticate with Podio at this time.</p>
                  <p>Please try again!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(null, mapDispatchToProps)(Authorizepass);
