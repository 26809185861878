/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import ViewConnector from "../../../components/Modal/Connector/ViewConnector";
import DeleteConnectorV2 from "../../../components/Modal/Connector/DeleteConnectorV2";
import { IconButton } from "@material-ui/core";
import ExternalConnector from "../../../components/Modal/ExternalConnector";
import { useHistory } from "react-router";
import RenderByPermission from "components/RenderByPermission";

const ConnectorAction = (props) => {
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;
    const history = useHistory()
    const [viewModal, setViewModal] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [itemData, setitemData] = useState(null);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const [openExternal, setOpenExternal] = useState(false);

    const openViewModal = (data) => {
        setSelectedConnector(data)
        setViewModal(true)
    };
    const closeViewModal = () => {
        setViewModal(false)
    };

    const OpenDeleteConnector = (data) => {
        setitemData(data.id)
        setOpenDelete(true)
    };
    const closeDeleteConnector = () => {
        setOpenDelete(false)
    };

    const closeExternalConnector = () => {
        setOpenExternal(false)
    };

    const openExternalConnector = (data) => {
        setSelectedConnector(data)
        setOpenExternal(true)
    };
    const openSqlMapper = (data) => {
        console.log(data);
        history.push({
            pathname: `/dashboard/mappers/${data.org_id}/${data.id}`,
            state: data
        });
    };

    const onRemoveSelected = useCallback(() => {
        const selectedData = props.api.getSelectedRows();
        props.api.applyTransaction({ remove: selectedData });
    }, []);

    const addConnectors = (val) => {
        console.log(val);
        props["api"].getSelectedNodes()[0].setData(val);
    };

    return (
        <>
            <ViewConnector
                openModal={viewModal}
                credential={selectedConnector}
                handleClose={closeViewModal}
            />
            <DeleteConnectorV2
                openModal={openDelete}
                handleClose={closeDeleteConnector}
                connector={itemData}
                removeConnector={onRemoveSelected}
            />

            <ExternalConnector
                openModal={openExternal}
                handleClose={closeExternalConnector}
                updateConnector={addConnectors}
                selectedConnector={selectedConnector}
                isEdited={true}
            />

            {data !== null ? (
                <center>
                    <i
                        className="fa fa-eye"
                        style={{
                            fontSize: "15px",
                            marginRight: "10px",
                            cursor: "pointer",
                        }}
                        title="View"
                        onClick={() => openViewModal(data)}
                    ></i>
                    <i
                        className="fa fa-database"
                        style={{
                            fontSize: "15px",
                            cursor: "pointer",
                        }}
                        title="Mappers"
                        onClick={() => openSqlMapper(data)}
                    ></i>
                    <IconButton
                        disabled={data.is_external === 0 ? true : false}
                        style={{
                            cursor: "pointer",
                            color: data.is_external === 1 ? "white" : "grey",
                            marginBottom: "5px"
                        }}
                        onClick={() => openExternalConnector(data)}
                    >
                        <i
                            className="fa fa-edit"
                            style={{
                                fontSize: "17px",
                                cursor: "pointer",
                                color: data.is_external === 1 ? "white" : "grey"
                            }}
                            title="Edit Connector"
                        ></i>
                    </IconButton>
                    <RenderByPermission allowTeamMemberAccess={false}>
                        <i
                            className="fa fa-trash"
                            style={{
                                fontSize: "15px",
                                cursor: "pointer",
                            }}
                            title="Delete"
                            onClick={() => OpenDeleteConnector(data)}
                        ></i> 
                    </RenderByPermission>
                </center>
            ) : (
                ""
            )}
        </>
    );
};

export default ConnectorAction;
