/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import DeleteMapperV2 from "../../../components/Modal/Connector/DeleteMapperV2";
import ResyncMysqlV2 from "../../../components/Modal/Connector/ResyncMysqlV2";
import ViewFieldsV2 from "../../../components/Modal/Connector/ViewFieldsV2";
import { toast } from "helpers/apiRequests"
import Axios from "../../../config";
import CreateNewDataMapModalV2 from "../../../components/Modal/Connector/CreateNewDataMapModalV2";
import SpinnerModal from "../../../components/Modal/ConfirmModal";
import { useSelector } from "react-redux";
import RenderByPermission from "components/RenderByPermission";

const MapperAction = (props) => {
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;
    const user = useSelector((state) => state.user.authUser);
    const [fields, setFields] = useState([]);
    const [openField, setOpenField] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [itemData, setitemData] = useState(false);
    const [resyncModal, setResyncModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [apps, setApps] = useState(null);
    const [selectedApp, setSelectedApp] = useState({});

    const handleOpenResyncModal = (data) => {
        setitemData(data);
        setResyncModal(true);
    };
    const handleCloseResyncModal = () => {
        setResyncModal(false);
    };

    const toggleFields = (data) => {
        setFields(data)
        setOpenField(true)
    };
    const closeOpenFields = () => {
        setOpenField(false)
    };

    const toggleDeleteMapper = (data) => {
        setitemData(data)
        setOpenDelete(true)
    };
    const closeDeleteMapper = () => {
        setOpenDelete(false)
    };

    const onRemoveSelected = useCallback(() => {
        const selectedData = props.api.getSelectedRows();
        props.api.applyTransaction({ remove: selectedData });
    }, []);

    const getApps = async (spaceId, orgId) => {
        try {
            setLoading(true);
            const data = await Axios.get(`/sync/podio/audit/apps?space_id=${spaceId}&org_id=${orgId}`);
            setApps(data.data);
            setLoading(false);
            return data.data;
        } catch (e) {
            setLoading(false);
            toast.notify(e?.response?.data?.message || "An Error occured", { type: "error" });
        }
    };

    const handleClickOpenItemModal = async (data) => {
        const selectedApps = await getApps(data.space_id, data.org_id);
        const currentApp = selectedApps.find((app) => {
            return app.app_id === data.app_id;
        });
        currentApp.org_id = data.org_id;
        currentApp.credential_id = data.credential_id;

        // setOpenItemModal(true);
        setOpenEdit(true)
        setSelectedApp(currentApp);
    };

    const handleCloseItemModal = () => {
        props.addMappers()
        setSelectedApp({});
        setOpenEdit(false);
    };



    return (
        <>
            <DeleteMapperV2
                openModal={openDelete}
                handleClose={closeDeleteMapper}
                itemData={itemData}
                removeMapper={onRemoveSelected}
            />

            <ResyncMysqlV2
                openModal={resyncModal}
                handleClose={handleCloseResyncModal}
                itemData={itemData}
                user={user}
            />
            <ViewFieldsV2
                openModal={openField}
                fields={fields}
                handleClose={closeOpenFields}
            />
            <CreateNewDataMapModalV2
                openItemModal={openEdit}
                handleCloseItemModal={handleCloseItemModal}
                selectedApp={selectedApp}
                connectorId={selectedApp?.credential_id}
                edit={true}
                type="edit"
            />
            <SpinnerModal
                openModal={loading}
                handleClose={() => setLoading(false)}
            />

            {data !== null ? (
                <center>
                    <i
                        className="fa fa-eye"
                        style={{
                            fontSize: "15px",
                            cursor: "pointer",
                        }}
                        title="View"
                        onClick={() => toggleFields(data.fields)}
                    ></i>

                    <i
                        className="fa fa-sync"
                        style={{
                            fontSize: "15px",
                            marginLeft: "10px",
                            cursor: "pointer",
                        }}
                        title="Resync"
                        onClick={() => handleOpenResyncModal(data)}
                    ></i>
                    <i
                        className="fa fa-edit"
                        style={{
                            fontSize: "15px",
                            marginLeft: "10px",
                            cursor: "pointer",
                        }}
                        title="Edit Mapper"
                        onClick={() => {
                            handleClickOpenItemModal(data);
                        }}
                    ></i>
                    <RenderByPermission allowTeamMemberAccess={false}>
                        <i
                            className="fa fa-trash"
                            style={{
                                fontSize: "15px",
                                marginLeft: "10px",
                                cursor: "pointer",
                            }}
                            title="Delete"
                            onClick={() => toggleDeleteMapper(data)}
                        ></i>
                    </RenderByPermission>
                </center>
            ) : (
                ""
            )}
        </>
    );
};

export default MapperAction;
