import React, { useEffect, useState } from "react";
import "./PlansAccount.scss";
import "./general.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useSelector } from "react-redux";
import { toast } from "helpers/apiRequests";
import CustomButton from "../../containers/Website/widgets/buttons/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
// icons
import check2 from "../../containers/Website/icons/check-mark2.svg";
import check4 from "../../assets/icons/checkbox.svg";
import arrow from "../../assets/icons/arrow-down2.svg";
import check3 from "../../containers/Website/icons/check-mark3.svg";
import dash from "../../containers/Website/icons/dash.svg";
import featureIcon from "../../assets/icons/featureIcon.svg";
import blueCheck from "../../assets/icons/blueCheck.svg";
import cancel from "../../assets/icons/cancel.svg";
import thatapp from "../../assets/icons/thatapp.svg";
import payment_successful from "../../assets/images/payment-successful.gif";
import useWindowSize from "hooks/useWindowSize";
import ManageCards from "./ManageCards";
import CountdownTimer from "containers/Sync/CountdownTimer";
import { useHistory } from "react-router";
import { cancelSubscription } from "services/sync";
import { getMe } from "services/auth";
import { useDispatch } from "react-redux";
import { setUser } from "redux/actions/user";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 2000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "30vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    padding: "2%",
    borderRadius: "12px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    position: "absolute",
    top: "0",
    width: "100%",
    padding: "0",
    overflowX: "scroll",
    height: "100vh",
  },
  bgGoldButton: {
    background: "#F7C948",
    color: "#fff",
    width: "100%",
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
  newSubCont: {
    backgroundColor: "#F9FDFF",
    padding: "3%",
    borderRadius: "10px",
    border: "1px solid #5ED0FA",
  },
  expired: {
    color: "#EF4E4E",
    fontWeight: "800",
  },
}));

const PlansAccount = ({
  user,
  plans,
  cardList,
  loadCards,
  reloadStore,
  userStatsData,
}) => {
  const classes = useStyles();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [, setUserUpgrade] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  const dispatch = useDispatch();
  // const section = urlParams.get("section");
  const redirectRoute = urlParams.get("redirect");
  // const message = urlParams.get("message");
  const history = useHistory();

  const handleClick = () => {
    setUserUpgrade(true);
    history.push({
      pathname: "/service",
      state: { userUpgrade: true },
    });
  };

  const invitation = useSelector((state) => state.user.invite);
  // const userDetail = useSelector((state) => state.user);
  const active_plan = user?.active_plan;
  const permission = user?.permissions;
  const free_trial = user?.free_trial;
  const plan_id = user?.active_plan?.id;
  const new_billing = user?.new_billing;
  const nextBillingDate = active_plan?.next_billing_date;

  const handleClose = () => {
    setOpen(false);
    if (redirectRoute) {
      window.location.href = redirectRoute;
    }
  };

  useEffect(() => {
    if (invitation.processing) {
      setOpen(false);
    }
  }, [invitation.processing]);

  const [allFeatures, setAllFeatures] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [plansSection, setPlansSection] = useState("choose");
  const [nextBilldate, setNextBilldate] = useState("");

  const handlePlanSection = (planSection) => {
    if (
      plansSection === "confirmation" &&
      paymentProcessing &&
      planSection !== "confirmation"
    ) {
      return;
    }

    if (
      (planSection === "payment" || planSection === "confirmation") &&
      isEmpty(selectedPlan)
    ) {
      toast.notify("You have to select a plan first.", { type: "info" });
      return;
    }

    if (!subscriptionStatus && planSection === "confirmation") {
      toast.notify("You need to make payment for a service first", {
        type: "info",
      });
      return;
    }
    setPlansSection(planSection);
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const handleSelectPlan = (planId) => {
    if (active_plan?.plan_id === planId) {
      toast.notify("You are already subscribed to this plan.", {
        type: "info",
      });
      setSelectedPlan({});
      return;
    }
    setPlansSection("payment");
  };

  const plansDescription = plans?.map((item) => item?.description_details);

  useEffect(() => {
    if (!nextBillingDate) {
      console.error("nextBillingDate is null or undefined");
      return;
    }

    const date = new Date(nextBillingDate);
    if (isNaN(date.getTime())) {
      console.error("Invalid date string:", nextBillingDate);
      return;
    }

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);
    setNextBilldate(formattedDate);
  }, [nextBillingDate]);

  const updateMe = async () => {
    try {
      const response = await getMe();
      setActivePlan(response.data.active_plan);
      dispatch(setUser(response.data));
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = async (e) => {
    if (reason === "") {
      toast.notify("Please enter a reason", {
        type: "warning",
      });
      return;
    }
    setLoading(true);
    e.preventDefault();
    try {
      const payload = {
        reason: reason,
        user_plan_id: plan_id,
      };
      await cancelSubscription(payload);
      toast.notify(
        "You have successfully unsubscribed to the recurring dynamic billing.",
        {
          type: "success",
        }
      );
      setCancelModal(false);
      setReason("");
      updateMe();
    } catch (e) {
      setCancelModal(false);
      setLoading(false);
    }
    setCancelModal(false);
    setLoading(false);
  };

  return (
    <div className="plans-account">
      {moreInfo && (
        <div className="modal_bg">
          <div className="modal_cont">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => setMoreInfo(false)}
                width="7%"
                src={cancel}
                alt=""
              />
            </div>

            <div
              style={{
                textAlign: "center",
                marginBottom: "2%",
                marginTop: "3%",
              }}
            >
              <p
                style={{ fontSize: "18px", fontWeight: "600", padding: "0 8%" }}
                className="mb-2 bold"
              >
                What you need to know about dynamic billing
              </p>

              <div className={classes.descInfo}>
                <p style={{ color: "#626262", fontSize: "12px" }}>
                  With dynamic billing, you are charged $30/1 million records of
                  your backed up organisation. Additional charges will be
                  applied for extra features used. Billing is done monthly for
                  the services you have used, and you can cancel anytime before
                  the next cycle to avoid further charges.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {cancelModal && (
        <div
          className={classes.modalOverlay}
          onClick={() => {
            setCancelModal(false);
          }}
        >
          <div
            className={classes.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="d-flex align-items-center justify-content-between">
              <h4>Cancel subscription</h4>
              <img
                onClick={() => {
                  setCancelModal(false);
                }}
                src={cancel}
                alt=""
              />
            </div>
            <div style={{ flexDirection: "column" }} className="d-flex">
              <textarea
                style={{
                  width: "100%",
                  height: "120px",
                  margin: "3% 0",
                  padding: "1.5% 2%",
                  borderRadius: "8px",
                  border: "1px solid lightgray",
                }}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Please enter your reason for cancellation"
              />
              <button
                style={{ width: "100%" }}
                onClick={handleCancel}
                className="generate_btn"
              >
                {loading ? (
                  <svg
                    className="spinner"
                    width="24px"
                    stroke="#fff"
                    height="24px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle"
                      fill="none"
                      strokeWidth="6"
                      strokeLinecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    ></circle>
                  </svg>
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <div style={isMobile ? { padding: "0" } : { padding: "1rem" }}>
        {free_trial === true && new_billing === false ? (
          <div>
            <div
              style={
                isMobile
                  ? { flexDirection: "column", height: "70vh" }
                  : { flexDirection: "column", height: "70vh" }
              }
              className="d-flex justify-content-between"
            >
              <div>
                <h5 className="bold">My Subscription Plan</h5>
                <div
                  style={{
                    border: "1px solid #fefefe",
                    boxShadow: "5px 5px 10px rgba(0,0,0,0.1)",
                    padding: "2%",
                    borderRadius: "10px",
                  }}
                  className="mt-3"
                >
                  {free_trial === true ? (
                    <div>
                      <div>
                        <p style={{ fontWeight: "600" }}>
                          ThatApp 15-day Free Trial
                        </p>
                        {free_trial === true ? (
                          <p className="mt-1">
                            You’re currently enjoying a 15-day free trial. Once
                            your trial ends, your data will be deleted from our
                            database
                          </p>
                        ) : (
                          <p className="mt-1">
                            Your 15-day free trial has expired. Kindly upgrade
                            now to avoid loosing your data
                          </p>
                        )}

                        <div className="d-flex justify-content-end">
                          <CountdownTimer />
                        </div>
                      </div>

                      <hr style={{ width: "100%" }} />

                      <div>
                        <p style={{ fontWeight: "600" }}>Dynamic Billing</p>
                        <p>
                          With dynamic billing, you are charged $30/1 million
                          records of your backed up organisation. Additional
                          charges will be applied for extra features used.
                          Billing is done monthly for the services you have
                          used, and you can cancel anytime before the next cycle
                          to avoid further charges.
                        </p>
                        <div className="d-flex justify-content-end">
                          <button
                            style={{
                              border: "1px solid #F0B429",
                              color: "#F0B429",
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                            }}
                            className="highlighted mt-3"
                            onClick={handleClick}
                          >
                            Upgrade
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p style={{ fontWeight: "600" }}>
                        ThatApp 15-day Free Trial
                      </p>
                      <p className="mt-1">
                        Your 15-day free trial has expired. Kindly upgrade now
                        to avoid loosing your data.
                      </p>
                      <div className="d-flex justify-content-between">
                        <button
                          style={{
                            border: "1px solid #F0B429",
                            color: "#F0B429",
                            borderRadius: "4px",
                            backgroundColor: "#fff",
                          }}
                          className="highlighted mt-3"
                          onClick={handleClick}
                        >
                          Upgrade
                        </button>
                        {free_trial === false ? (
                          <div className="d-flex justify-content-end">
                            <CountdownTimer />
                          </div>
                        ) : (
                          <div className="d-flex justify-content-end">
                            <h5 className={classes.expired}>Expired</h5>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : new_billing === true ? (
          <div
            style={
              isMobile
                ? { flexDirection: "column", height: "70vh" }
                : { flexDirection: "column", height: "70vh" }
            }
            className="d-flex justify-content-between"
          >
            <div className={classes.newSubCont}>
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Accumulated Bill</h5>
                  <p style={{ color: "#40C3F7", fontWeight: "600" }}>
                    {active_plan?.days_left} days remaining
                  </p>
                </div>

                <div className="mt-2">
                  <h3 style={{ color: "#40C3F7", fontWeight: "700" }}>
                    ${userStatsData?.data?.total}
                    <span
                      style={{
                        color: "#000",
                        fontWeight: "500",
                        fontSize: "18px",
                        marginLeft: "3px",
                      }}
                    >
                      / month
                    </span>
                  </h3>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="mb-0">Dynamic Billing</p>
                  <h5 style={{ color: "#40C3F7", fontWeight: "700" }}>
                    $30/
                    <span
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      1 million record
                    </span>
                  </h5>
                </div>
              </div>

              <div>
                <hr />
              </div>

              <div>
                <p style={{ fontWeight: "700", fontSize: "18px" }}>
                  Additional services used
                </p>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <p>Emails</p>
                  {userStatsData?.data?.extra?.Email ? (
                    <p>${userStatsData?.data?.extra?.Email}</p>
                  ) : (
                    <p>$0.00</p>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p>Print</p>
                  {userStatsData?.data?.extra?.Print ? (
                    <p>${userStatsData?.data?.extra?.Print}</p>
                  ) : (
                    <p>$0.00</p>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p>Cloning</p>
                  {userStatsData?.data?.extra?.Clone ? (
                    <p>${userStatsData?.data?.extra?.Clone}</p>
                  ) : (
                    <p>$0.00</p>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p>Database syncing</p>
                  {userStatsData?.data?.extra?.DbSync ? (
                    <p>${userStatsData?.data?.extra?.DbSync}</p>
                  ) : (
                    <p>$0.00</p>
                  )}
                </div>
              </div>

              <div>
                <hr />
              </div>

              <div className="d-flex align-items-center justify-content-end">
                <p style={{ color: "#B1B1B1", fontWeight: "500" }}>
                  Next billing date:{" "}
                  {new_billing === true ? (
                    <span style={{ color: "#000", fontWeight: "600" }}>
                      {nextBilldate}
                    </span>
                  ) : null}
                </p>
              </div>
            </div>

            {activePlan?.auto_renew === true || active_plan.auto_renew === true ? (
              <div
                style={
                  isMobile
                    ? { width: "100%", fontSize: "14px", marginBottom: "5%" }
                    : { width: "90%", fontSize: "14px" }
                }
              >
                <p>
                  When you cancel your subscription, you will lose access to all
                  premium features that come with your plan. Click here to read
                  our pricing policy
                </p>
                <p
                  style={{
                    color: "#EF4E4E",
                    borderBottom: "1px solid",
                    width: "fit-content",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    setCancelModal(true);
                  }}
                  className="mt-3"
                >
                  Cancel Subscription
                </p>
              </div>
            ) : (
              <div
                style={
                  isMobile
                    ? { width: "100%", fontSize: "14px", marginBottom: "5%" }
                    : { width: "90%", fontSize: "14px" }
                }
              >
                <p>
                  Your current subscription has been cancelled. However, your
                  plan is still active.
                </p>
                <p
                  style={{
                    color: "#EF4E4E",
                    borderBottom: "1px solid",
                    width: "fit-content",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  onClick={handleClick}
                  className="mt-3"
                >
                  Click here to resubscribe
                </p>
              </div>
            )}
          </div>
        ) : (
          <div>
            {active_plan ? (
              <div
                style={
                  isMobile
                    ? { flexDirection: "column", height: "70vh" }
                    : { flexDirection: "column", height: "70vh" }
                }
                className="d-flex justify-content-between"
              >
                <div>
                  <h5 className="bold">My Subscription Plan</h5>
                  <div
                    style={isMobile ? { flexDirection: "column" } : null}
                    className="d-flex align-items-center justify-content-between mt-3"
                  >
                    <div
                      style={
                        isMobile
                          ? {
                              width: "100%",
                              alignItems: "flex-start",
                            }
                          : {
                              width: "48%",
                              alignItems: "flex-start",
                            }
                      }
                      className="d-flex"
                    >
                      <div
                        style={
                          isMobile
                            ? {
                                width: "100%",
                                backgroundColor: "#F9FDFF",
                                border: "1px solid #5ED0FA",
                                borderRadius: "5px",
                                padding: "3%",
                              }
                            : {
                                backgroundColor: "#F9FDFF",
                                border: "1px solid #5ED0FA",
                                borderRadius: "5px",
                                padding: "3%",
                                width: "100%",
                              }
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="bold">{active_plan?.planName}</h5>
                            <p>{active_plan?.days_left} days remaining</p>
                          </div>

                          <div>
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                ${active_plan?.plan?.price}
                              </span>
                              /month
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between mt-4 align-items-center">
                          <div>
                            <p>Feature</p>
                            <div className="d-flex align-items-center">
                              <img width="20px" src={featureIcon} alt="" />
                              {active_plan?.planName === "Starter" ? (
                                <p style={{ marginBottom: "0" }}>
                                  1 Organisations
                                </p>
                              ) : active_plan?.planName === "Business" ? (
                                <p style={{ marginBottom: "0" }}>
                                  2 Organisations
                                </p>
                              ) : active_plan?.planName === "Enterprise" ? (
                                <p style={{ marginBottom: "0" }}>
                                  4 Organisations
                                </p>
                              ) : active_plan?.planName === "Data Scientist" ? (
                                <p style={{ marginBottom: "0" }}>
                                  10 Organisations
                                </p>
                              ) : active_plan?.planName === "White Glove" ? (
                                <p style={{ marginBottom: "0" }}>
                                  15 Organisations
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div
                            style={{
                              width: "45%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              style={{
                                backgroundColor: "#F0FBFF",
                                color: "#40C3F7",
                                borderRadius: "30px",
                                border: "1px solid #40C3F7",
                                fontSize: "16px",
                                height: "45px",
                                padding: "5% 10%",
                                cursor: "default",
                              }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "-5px", marginLeft: "-10px" }}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <img src={blueCheck} alt="" />
                      </div>
                    </div>

                    {active_plan?.planName === "White Glove" ? null : (
                      <div>
                        {isMobile && (
                          <div className="mt-3 mb-3">
                            <p>Next upgradable plan</p>
                          </div>
                        )}
                      </div>
                    )}

                    {active_plan?.planName === "White Glove" ? null : (
                      <div
                        style={
                          isMobile
                            ? {
                                width: "100%",
                                backgroundColor: "#2B79ED",
                                color: "white",
                                borderRadius: "5px",
                                padding: "3%",
                              }
                            : {
                                backgroundColor: "#2B79ED",
                                color: "white",
                                borderRadius: "5px",
                                padding: "2%",
                                width: "48%",
                              }
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="bold">Dynamic billing</h5>
                          </div>
                        </div>

                        <div className="mt-1">
                          <p>
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginRight: "3px",
                              }}
                            >
                              $30
                            </span>
                            / 1 million records
                          </p>
                        </div>

                        <div className="d-flex justify-content-between mt-4 align-items-center">
                          <div>
                            <button
                              style={{
                                backgroundColor: "white",
                                color: "#40C3F7",
                                borderRadius: "30px",
                                border: "none",
                                fontSize: "16px",
                                height: "40px",
                                padding: "5% 25%",
                              }}
                              onClick={handleClick}
                            >
                              Upgrade
                            </button>
                          </div>

                          <div
                            onClick={() => setMoreInfo(true)}
                            style={{ width: "fit-content", textAlign: "right" }}
                          >
                            <p>Learn more about this plan</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {activePlan?.auto_renew === true || active_plan.auto_renew === true ? (
                  <div
                    style={
                      isMobile
                        ? {
                            width: "100%",
                            fontSize: "14px",
                            marginBottom: "5%",
                          }
                        : { width: "90%", fontSize: "14px" }
                    }
                  >
                    <p>
                      When you cancel your subscription, you will lose access to
                      all premium features that come with your plan. Click here
                      to read our pricing policy
                    </p>
                    <p
                      style={{
                        color: "#EF4E4E",
                        borderBottom: "1px solid",
                        width: "fit-content",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setCancelModal(true);
                      }}
                      className="mt-3"
                    >
                      Cancel Subscription
                    </p>
                  </div>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            width: "100%",
                            fontSize: "14px",
                            marginBottom: "5%",
                          }
                        : { width: "90%", fontSize: "14px" }
                    }
                  >
                    <p>
                      Your current subscription has been cancelled. However,
                      your plan is still active.
                    </p>
                    <p
                      style={{
                        color: "#EF4E4E",
                        borderBottom: "1px solid",
                        width: "fit-content",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={handleClick}
                      className="mt-3"
                    >
                      Click here to resubscribe
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div
                  style={
                    isMobile
                      ? { flexDirection: "column", height: "70vh" }
                      : { flexDirection: "column", height: "70vh" }
                  }
                  className="d-flex justify-content-between"
                >
                  <div>
                    <h5 className="bold">My Subscription Plan</h5>
                    <div
                      style={{
                        border: "1px solid #fefefe",
                        boxShadow: "5px 5px 10px rgba(0,0,0,0.1)",
                        padding: "2%",
                        borderRadius: "10px",
                      }}
                      className="mt-3"
                    >
                      <div>
                        {user?.has_done_free_trial === false && (
                          <div>
                            <div>
                              <p style={{ fontWeight: "600" }}>
                                Start Your Free Trial
                              </p>
                              <p className="mt-1">
                                Welcome back! You don’t have a plan yet. Start
                                your free trial today and explore our features
                                for a limited time
                              </p>
                              <div className="d-flex justify-content-end">
                                <button
                                  style={{
                                    border: "1px solid #F0B429",
                                    color: "#F0B429",
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                  }}
                                  className="highlighted mt-3"
                                  onClick={handleClick}
                                >
                                  Start free trial
                                </button>
                              </div>
                            </div>

                            <hr style={{ width: "100%" }} />
                          </div>
                        )}

                        <div>
                          <p style={{ fontWeight: "600" }}>Dynamic Billing</p>
                          <p>
                            With dynamic billing, you are charged $30/1 million
                            records of your backed up organisation. Additional
                            charges will be applied for extra features used.
                            Billing is done monthly for the services you have
                            used, and you can cancel anytime before the next
                            cycle to avoid recuring charges.
                          </p>
                          <div className="d-flex justify-content-end">
                            <button
                              style={{
                                border: "1px solid #F0B429",
                                color: "#F0B429",
                                borderRadius: "4px",
                                backgroundColor: "#fff",
                              }}
                              className="highlighted mt-3"
                              onClick={handleClick}
                            >
                              Subscribe to a new plan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* <div className="right">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "4rem" }}
            onClick={handleOpen}
          >
            {permission ? "Upgrade" : "Select Plan"}
          </Button>
        </div> */}
      </div>

      {/* {permission && (
        <div className="body">
          <div className="small">The features of your current plan are</div>
          <div className="list">
            <div>
              No. orgs: <b>{permission?.orgs}</b>
            </div>
            <div>
              Iris:{" "}
              <b>
                {permission?.iris ? "Available" : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Audit:{" "}
              <b>
                {permission?.audit ? "Available" : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Restore:{" "}
              <b>
                {permission.restore
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Live Data:{" "}
              <b>
                {permission.live_data
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              Data Mesh:{" "}
              <b>
                {permission.data_mesh
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              App Builder:{" "}
              <b>
                {permission.app_builder
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
            <div>
              File Haven:{" "}
              <b>
                {permission.file_haven
                  ? "Available"
                  : "Not Available for this plan"}
              </b>
            </div>
          </div>
        </div>
      )} */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={`${classes.paper} plans-account-modal pricing-pricing`}
          >
            <div className="inner-account-modal">
              <div className="header">
                <img src={thatapp} alt="ThatApp" className="logo" />
                <div className="modal-navigation">
                  <RenderPlanHeader selected={plansSection === "choose"}>
                    <span
                      onClick={() => handlePlanSection("choose")}
                      className={plansSection === "choose" ? "active" : ""}
                    >
                      1. Choose a plan
                    </span>
                  </RenderPlanHeader>
                  <RenderPlanHeader selected={plansSection === "payment"}>
                    <span
                      onClick={() => handlePlanSection("payment")}
                      className={plansSection === "payment" ? "active" : ""}
                    >
                      2. Payment
                    </span>
                  </RenderPlanHeader>
                  {/* <RenderPlanHeader selected={plansSection === "confirmation"}>
                    <span
                      onClick={() => handlePlanSection("confirmation")}
                      className={
                        plansSection === "confirmation" ? "active" : ""
                      }
                    >
                      3. Confirmation
                    </span>
                  </RenderPlanHeader> */}
                </div>
                <span onClick={handleClose} className="close">
                  &times;
                </span>
              </div>
              <div className="modal-container">
                {plansSection === "choose" && (
                  <div className="plans">
                    <div className="plans-wrapper">
                      <div className="plans-container">
                        {plans.map((plan, index) => {
                          let description = JSON.parse(plan.description);
                          let meta = JSON.parse(plan.meta);

                          return (
                            <div
                              className={
                                active_plan?.plan_id === plan.id
                                  ? "item active-plan"
                                  : selectedPlan?.id === plan.id
                                  ? "item item-active"
                                  : "item"
                              }
                              key={index}
                            >
                              <div className="content">
                                <div className="font-22 bolder name">
                                  {plan.name}
                                </div>
                                {/* <p className="font-14">
                                    For entrepreneurs and teams to start selling smarter.
                                  </p> */}
                                <div className="color-1 price2">
                                  <span className="font-34">${plan.price}</span>
                                  <span className="font-24">/Month</span>
                                </div>
                                <button
                                  className={`cta ${
                                    selectedPlan.id === plan.id
                                      ? "bg-bd6-6-fx button-4"
                                      : "bg-bd5-5-fx button-4"
                                  }`}
                                  onClick={() => {
                                    setSelectedPlan(plan);
                                    handleSelectPlan(plan.id);
                                  }}
                                >
                                  {selectedPlan?.id === plan.id
                                    ? "Selected"
                                    : plan.text}
                                </button>
                                <div className="features">
                                  <div>
                                    <img
                                      src={
                                        active_plan?.plan_id === plan.id
                                          ? check4
                                          : check2
                                      }
                                      alt={
                                        meta?.sync?.organisations
                                          ? "check"
                                          : "dash"
                                      }
                                    />{" "}
                                    {description?.orgs === 1 && (
                                      <span>
                                        {description?.orgs} Organisation
                                      </span>
                                    )}
                                    {description?.orgs > 1 && (
                                      <span>
                                        {description?.orgs} Organisations
                                      </span>
                                    )}
                                  </div>
                                  {/* <div>
                                      <img src={active_plan?.plan_id === plan.id ? check4 : check2} alt={meta?.sync?.organisations ? "check" : "dash"} />{" "}
                                      <span>{meta?.connection} Connection{meta?.connection > 1 && "s"}</span>
                                    </div> */}
                                </div>
                                <div className="see-more color-1"></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      onClick={() => setAllFeatures(!allFeatures)}
                      className={
                        allFeatures
                          ? "all-features-navigation bg-11 container-container all-features-navigation-active text-center"
                          : "bg-11 container-container all-features-navigation text-center"
                      }
                    >
                      See all Features <img src={arrow} alt="arrow" />
                    </div>
                    <div
                      className={
                        allFeatures
                          ? "container-container all-features all-features-active"
                          : "container-container all-features"
                      }
                    >
                      <div className="plan-details container-container">
                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">SYNC</div>
                                <div className="color-black font-20">
                                  Features
                                </div>
                              </div>
                              <div className="tab">Organisations</div>
                              <div className="tab">Space</div>
                              <div className="tab">App</div>
                              <div className="tab">Item</div>
                              <div className="tab">Revisions</div>
                              <div className="tab">Comments</div>
                              <div className="tab">Hooks</div>
                              <div className="tab">Contacts</div>
                              <div className="tab">Tasks</div>
                              <div className="tab">Files</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.organisations
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.spaces ? check3 : dash}
                                        alt={
                                          meta?.sync?.spaces ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.apps ? check3 : dash}
                                        alt={
                                          meta?.sync?.apps ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.items ? check3 : dash}
                                        alt={
                                          meta?.sync?.items ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.revisions ? check3 : dash
                                        }
                                        alt={
                                          meta?.sync?.revisions
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.comments ? check3 : dash
                                        }
                                        alt={
                                          meta?.sync?.comments
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.hooks ? check3 : dash}
                                        alt={
                                          meta?.sync?.hooks ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.sync?.contacts ? check3 : dash
                                        }
                                        alt={
                                          meta?.sync?.contacts
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.tasks ? check3 : dash}
                                        alt={
                                          meta?.sync?.tasks ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.sync?.files ? check3 : dash}
                                        alt={
                                          meta?.sync?.files ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">
                                  Data Interaction
                                </div>
                              </div>
                              <div className="tab">Item Creation</div>
                              <div className="tab">Custom Filters</div>
                              <div className="tab">Row Grouping</div>
                              <div className="tab">Pivot Reporting</div>
                              <div className="tab">Real time Data</div>
                              <div className="tab">Data Export</div>
                              <div className="tab">Saved Views</div>
                              <div className="tab">Cell Editing</div>
                              <div className="tab">Nested Files</div>
                              <div className="tab">Sync Charts</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);

                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.item_creation
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.custom_filters
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.row_grouping
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction
                                            ?.pivot_reporting
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.real_time_data
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.data_export
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.saved_views
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.cell_editing
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.nested_files
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_interaction?.sync_charts
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.sync?.organisations
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    onClick={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {selectedPlan.id === plan.id
                                      ? "Selected"
                                      : plan.text}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">
                                  Data Restoration
                                </div>
                              </div>
                              <div className="tab">Items</div>
                              <div className="tab">Comments</div>
                              <div className="tab">Tasks</div>
                              <div className="tab">Files</div>
                              <div className="tab">App Fields</div>
                              <div className="tab">Applications</div>
                              <div className="tab">Workspaces</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.items
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.items
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.comments
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.comments
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.tasks
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.tasks
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.files
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.files
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.app_fields
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.app_fields
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.applications
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.applications
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.data_restoration?.workspaces
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.data_restoration?.workspaces
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">Tools</div>
                              </div>
                              <div className="tab">GDPR Settings</div>
                              <div className="tab">Hook Killer</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.tools?.gdpr_settings
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.tools?.gdpr_settings
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.tools?.hook_killer
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.tools?.hook_killer
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">AUDIT</div>
                              </div>
                              <div className="tab">Queue Management</div>
                              <div className="tab">User Management</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.audit?.queue_management
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.audit?.queue_management
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.audit?.user_management
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.audit?.user_management
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">
                                  Event Logging
                                </div>
                              </div>
                              <div className="tab">Creation</div>
                              <div className="tab">Updates</div>
                              <div className="tab">Deletes</div>
                              <div className="tab">Shared Items</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.creation
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.creation
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.updates
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.updates
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.deletes
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.deletes
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.event_logging?.shared_items
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.event_logging?.shared_items
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">
                                  FILE HAVEN
                                </div>
                              </div>
                              <div className="tab">Logic Builder</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.file_haven?.logic_builder
                                            ? check3
                                            : dash
                                        }
                                        alt={
                                          meta?.file_haven?.logic_builder
                                            ? "check"
                                            : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {/* <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">IRIS GENESIS</div>
                              </div>
                              <div className="tab">Queue Management</div>
                              <div className="tab">User Management</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {
                              plans.map((plan, index) => {
                                let meta = JSON.parse(plan.meta);
                                return (
                                  <div className="item" key={index}>
                                    <div className="inner">
                                      <div className="tab-header">
                                        <div className="color-black font-20 bold">
                                          {plan.name}
                                        </div>
                                        <div>{plan.currency}{plan.price} / Month</div>
                                      </div>
                                      <div className="tab">
                                        <img src={meta?.iris_genesis?.queue_management ? check3 : dash} alt={meta?.iris_genesis?.queue_management ? "check" : "dash"} />
                                      </div>
                                      <div className="tab">
                                        <img src={meta?.iris_genesis?.user_management ? check3 : dash} alt={meta?.iris_genesis?.user_management ? "check" : "dash"} />
                                      </div>
                                    </div>
                                    <CustomButton
                                      text={selectedPlan.id === plan.id ? "Selected" : plan.text}
                                      styling={active_plan?.plan_id === plan.id ? "disabled bg-bd6-6-fx button-4" : (
                                        selectedPlan.id === plan.id ? "bg-bd6-6-fx button-4" :
                                          "bg-bd5-5-fx button-4")}
                                      function={() => {
                                        setSelectedPlan(plan);
                                        handleSelectPlan(plan.id)
                                      }}
                                    />
                                  </div>
                                );
                              })
                            }

                          </div>
                        </div> */}

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-1 font-24">PRINT</div>
                              </div>
                              <div className="tab">Templates</div>
                              <div className="tab">Production</div>
                              <div className="tab">Development</div>
                              <div className="tab">JSON</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      {meta?.print?.templates}
                                    </div>
                                    <div className="tab">
                                      {meta?.print?.production}
                                    </div>
                                    <div className="tab">
                                      {meta?.print?.development}
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={meta?.print?.json ? check3 : dash}
                                        alt={
                                          meta?.print?.json ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="double-container-1-5">
                          <div className="features item">
                            <div className="inner">
                              <div className="bold tab-header">
                                <div className="color-black font-20">AVA</div>
                              </div>
                              <div className="tab">Workspaces</div>
                              <div className="tab">Production</div>
                              <div className="tab">Development</div>
                              <div className="tab">Git Repo</div>
                            </div>
                          </div>
                          <div className="quintuple-container2">
                            {plans.map((plan, index) => {
                              let meta = JSON.parse(plan.meta);
                              return (
                                <div className="item" key={index}>
                                  <div className="inner">
                                    <div className="tab-header">
                                      <div className="color-black font-20 bold">
                                        {plan.name}
                                      </div>
                                      <div>
                                        {plan.currency}
                                        {plan.price} / Month
                                      </div>
                                    </div>
                                    <div className="tab">
                                      {meta?.ava?.workspaces}
                                    </div>
                                    <div className="tab">
                                      {meta?.ava?.production}
                                    </div>
                                    <div className="tab">
                                      {meta?.ava?.development}
                                    </div>
                                    <div className="tab">
                                      <img
                                        src={
                                          meta?.ava?.git_repo ? check3 : dash
                                        }
                                        alt={
                                          meta?.ava?.git_repo ? "check" : "dash"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <CustomButton
                                    text={
                                      selectedPlan.id === plan.id
                                        ? "Selected"
                                        : plan.text
                                    }
                                    styling={
                                      active_plan?.plan_id === plan.id
                                        ? "disabled bg-bd6-6-fx button-4"
                                        : selectedPlan.id === plan.id
                                        ? "bg-bd6-6-fx button-4"
                                        : "bg-bd5-5-fx button-4"
                                    }
                                    function={() => {
                                      setSelectedPlan(plan);
                                      handleSelectPlan(plan.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <ManageCards
                  {...{
                    user,
                    plansSection,
                    loadCards,
                    cardList,
                    reloadStore,
                    selectedPlan,
                    active_plan,
                    setSubscriptionStatus,
                    setPaymentProcessing,
                    setPlansSection,
                    permission,
                    plansDescription,
                  }}
                />

                {plansSection === "confirmation" ? (
                  paymentProcessing ? (
                    <div className="confirmation">
                      <div className="container">
                        <img src={payment_successful} alt="Successful" />
                        <div className="font-24 bold">All done!</div>
                        <div className="font-20 color-10">
                          You’ve successfully subscribed to a{" "}
                          {selectedPlan?.name} (Monthly) Plan
                        </div>
                        <CustomButton
                          text="Continue to dashboard"
                          styling="bg-bd2-2 font-12 button-4"
                          function={handleClose}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PlansAccount;

function RenderPlanHeader({ selected, children }) {
  const { width } = useWindowSize();
  const isMobile = width < 650;

  if (!isMobile) return children;

  if (isMobile && selected) {
    return children;
  } else {
    return null;
  }
}
