/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import "./style.css";
import { useSelector } from "react-redux";
import Error from "../../../containers/Mysql/components/Error/Error";
import { useHistory } from "react-router";
import CredentialModal from "../CredentialModal";
import RenderByPermission from "components/RenderByPermission";
import Axios from "config";
import MappersInput from "components/atoms/MappersInput";
import cancel from "../../../assets/icons/cancel.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import mapperBack from "../../../assets/icons/mapperBack.svg";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2% 4% 0 4%",
    borderBottom: "1px solid #F7F7F7",
  },
  headerText: {
    color: "black",
  },
  root: {
    display: "flex",
  },
  formControl: {
    // margin: theme.spacing(3),
  },
  textInput: {
    fontSize: "11px",
  },
  navTab: {
    backgroundColor: "#F7F7F7",
    padding: "2% 3%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "3%",
  },
  nav1: {
    backgroundColor: "#F7C948",
    borderRadius: "50px",
    textAlign: "center",
    padding: "5%",
    color: "white",
    width: "50%",
  },
  nav2: {
    // backgroundColor: "#F7C948",
    borderRadius: "50px",
    textAlign: "center",
    padding: "5%",
    // color: "white",
    width: "50%",
  },
}));
const CreateNewDataMapModalV2 = ({
  openItemModal,
  handleCloseItemModal,
  selectedApp,
  connectorId,
  addMappers,
  isConnector,
  edit,
  ...props
}) => {
  const history = useHistory();
  // const user = useSelector((state) => state.user.authUser);
  const connectors = useSelector((state) => state.connector.database);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectApps, setSelectApps] = useState(false);
  const tabs = ["Basic", "Advanced"];

  const token = localStorage.getItem("chosen_token");

  let fields = useMemo(
    () => selectedApp?.full_app_object?.fields || [],
    [selectedApp?.full_app_object?.fields]
  );

  const [advancedFields, setAdvancedFields] = useState([]);
  const classes = useStyles();

  const [actionLoader, setActionLoader] = useState({
    id: "",
    loading: false,
  });

  const handleSelectedAppClick = () => {
    setSelectApps(!selectApps);
  };

  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [fetchedSubTypes, setFetchedSubTypes] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [hideButton, setHideButton] = useState(null);
  const [formArray, setFormArray] = useState([]);
  const [advancedFormArray, setAdvancedFormArray] = useState([]);
  const [item, setItem] = useState({});
  const [pushData, setPushData] = useState(0);
  const [selectedMapper, setSelectedMapper] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [deleteFromPodio, setDeleteFromPodio] = useState(0);

  const closeCredentialModal = () => setOpen(false);
  const changeSelectedConncector = (val) => setSelectedConnector(val);

  const [activeTab, setActiveTab] = useState("Basic");

  const toggleTab = (e) => {
    const name = e.target.getAttribute("data-name");
    setActiveTab(name);
  };

  let fielsdToMap = activeTab === tabs[0] ? fields : advancedFields;
  let formsToMap = activeTab === tabs[0] ? formArray : advancedFormArray;

  let url;

  useEffect(() => {
    setHideButton(edit);
  }, [edit]);

  if (connectorId) {
    url = `${process.env.REACT_APP_BASEURL}/mappers?app_id=${selectedApp.app_id}&credential_id=${connectorId}`;
  } else {
    url = `${process.env.REACT_APP_BASEURL}/mappers?app_id=${selectedApp.app_id}`;
  }

  const getMapper = useCallback(
    async (activeTab, tabs) => {
      setFetchingData(true);
      try {
        let data = await Axios.get(url);
        if (data.length > 0) {
          // setHideButton(true);
          let content = data[0];
          if (content) {
            setDeleteFromPodio(content.force_delete);
          }
          setSelectedMapper(content);
          let obj = {
            // ...item
          };
          let idArray = [];
          let subTypesArray = [];
          let fields;
          let newFields = [];
          if (Array.isArray(content.fields)) {
            fields = content.fields;
          } else {
            fields = Object.values(content.fields);
          }
          await fields.forEach((data) => {
            let foundData = newFields.filter(
              (a) => a.field_id === data.field_id
            )[0];
            if (activeTab === "Basic" && obj[data.field_id] === undefined) {
              obj[data.field_id] = true;
              if (!foundData) {
                newFields.push(data);
              }
            } else if (activeTab === "Advanced") {
              const child_type = data.child_type || data.default_child_type;

              obj[data.field_id] = true;

              const fieldId = !!child_type
                ? `${data.field_id},${
                    data.sub_type || data.default_sub_type
                  },${child_type}`
                : `${data.field_id},${data.sub_type || data.default_sub_type}`;

              obj[`${fieldId}`] = true;

              data.viewLabel = !!child_type
                ? `${data.label}|${
                    data.sub_type || data.default_sub_type
                  }|${child_type}`
                : `${data.label}|${data.sub_type || data.default_sub_type}`;

              subTypesArray.push(
                `${data.field_id},${data.sub_type || data.default_sub_type},${
                  data.child_type || data.default_child_type
                }`
              );
              idArray.push(
                `${data.field_id},${data.sub_type || data.default_sub_type},${
                  data.child_type || data.default_child_type
                }`
              );
            }
            idArray.push(data.field_id);
          });

          setItem(obj);
          // setFormArray(fields)
          if (activeTab === "Basic") {
            setFormArray(newFields);
          } else {
            setAdvancedFormArray(fields);
          }
          setFetchedData(idArray);
          setFetchedSubTypes(subTypesArray);
        } else {
          // setHideButton(false);
        }
      } catch (e) {
      } finally {
        setFetchingData(false);
      }
    },
    [selectedApp.app_id, token]
  );

  // useEffect(() => {
  //   setItem({});
  //   setFormArray([]);
  //   setAdvancedFormArray([]);
  //   setCheckAll(false);
  //   if (
  //     selectedApp.app_id &&
  //     user &&
  //     user.mysql_syncing?.mysql_status === true
  //   ) {
  //     getMapper(activeTab);
  //   }
  // }, [selectedApp, user, getMapper, activeTab]);

  useEffect(() => {
    if (fields.length > 0) {
      let newAdvanced = [];
      for (let i = 0; i < fields.length; i++) {
        let { options } = fields[i];
        for (let j = 0; j < options?.length; j++) {
          let element = options[j];
          // if (element.schema_type === "advanced") {
          if (element.sub_type && element.sub_type !== null) {
            let filterSearch = newAdvanced.filter(
              (val) => val.field_id === fields[i].field_id
            )[0];
            if (!filterSearch) {
              newAdvanced.push(fields[i]);
            }
          }
        }
      }
      setAdvancedFields(newAdvanced);
    }
  }, [fields, activeTab]);

  const updatePushData = ({ target: { checked } }) => {
    const pushData = checked ? 1 : 0;
    setPushData(pushData);
  };

  const updateDeleteFromPodio = ({ target: { checked } }) => {
    const deleteFromPodio = checked ? 1 : 0;
    setDeleteFromPodio(deleteFromPodio);
  };

  const handleChange = ({ target: { name, checked } }) => {
    if (checked === true) {
      if (formArray.length + 1 === fields.length) {
        setCheckAll(true);
      }

      let newField = fields.filter(
        (param) => param.field_id === parseInt(name)
      )[0];
      newField.advanced = activeTab === tabs[1] ? 1 : 0;

      setFormArray([...formArray, newField]);

      if (activeTab === tabs[1]) {
        newField.options.forEach((option) => {
          const name = [
            newField.field_id,
            option.sub_type,
            ...(!option.child_type && !option.default_child_type
              ? []
              : [`${option.child_type || option.default_child_type}`]),
          ];
          const itemToUpdate = {
            ...item,
            // [`${name}`]: checked,
            [`${name}`]: true,
          };
          setItem(itemToUpdate);
        });
        const itemToUpdate = {
          ...item,
          // [`${name}`]: checked,
          "232209470,duration": true,
        };

        setItem(itemToUpdate);

        setTimeout(function () {}, 3000);
      }
    } else {
      if (formArray.length - 1 < fields.length) {
        setCheckAll(false);
      }

      let newArr = formArray.filter(
        (param) => param.field_id !== parseInt(name)
      );
      setFormArray(newArr);
    }
    setItem({ ...item, [name]: checked });
  };

  const handleChangeOptions = ({ target: { name, checked } }) => {
    name = name.split(",");

   

    let newField = fields.filter(
      (param) => param.field_id === parseInt(name[0])
    )[0];
    if (checked === true) {
      if (advancedFormArray.length + 1 === fields.length) {
        setCheckAll(true);
      }

      let fieldToModify = { ...newField };
      fieldToModify.viewLabel = name[2]
        ? `${newField.label}|${name[1]}|${name[2]}`
        : `${newField.label}|${name[1]}`;

      fieldToModify.sub_type = name[1];
      fieldToModify.advanced = activeTab === tabs[1] ? 1 : 0;
      fieldToModify.child_type = name[2] !== undefined ? name[2] : null;

      setAdvancedFormArray([...advancedFormArray, fieldToModify]);
    } else {
      if (advancedFormArray.length - 1 < fields.length) {
        setCheckAll(true);
      }

      const fieldToFilterBy = name[2]
        ? `${newField.label}|${name[1]}|${name[2]}`
        : `${newField.label}|${name[1]}`;
      let newArr = advancedFormArray.filter(
        (param) => param.viewLabel !== fieldToFilterBy
      );
      setAdvancedFormArray(newArr);
    }

    const itemToUpdate = { ...item, [`${name}`]: checked };
    setItem(itemToUpdate);
  };

  const handleCheckAll = (e) => {
    if (checkAll) {
      setFormArray([]);
      setAdvancedFormArray([]);
      setItem({});
    } else {
      let newFieldsArray = [];
      let newAdvFieldsArray = [];
      let objToAdd = {};

      fields.forEach((field) => {
        if (field.status === "deleted") {
          return;
        }
        if (!formArray.includes(field.field_id)) {
          field.advanced = activeTab === tabs[1] ? 1 : 0;

          if (activeTab === tabs[1]) {
            let dataArray = field.options;
            if (typeof field.options === "object" && field.options !== null) {
              dataArray = Object.values(field.options);
            }

            dataArray.forEach((option) => {
              if (
                // field.type === "text" &&
                field.status !== "deleted"
                // field.config.settings.size === "small" &&
                // option.sub_type === "text"
              ) {
                const propertyName = `${field.field_id},${
                  option.sub_type || option.default_sub_type
                }${
                  !option.child_type && !option.default_child_type
                    ? ""
                    : `,${option.child_type || option.default_child_type}`
                }`;

                let name = propertyName.split(",");
                let newField = fields.filter(
                  (param) => param.field_id === parseInt(name[0])
                )[0];
                let fieldToModify = { ...newField };
                fieldToModify.viewLabel = name[2]
                  ? `${newField.label}|${name[1]}|${name[2]}`
                  : `${newField.label}|${name[1]}`;

                fieldToModify.sub_type = name[1];
                fieldToModify.advanced = activeTab === tabs[1] ? 1 : 0;
                fieldToModify.child_type =
                  name[2] !== undefined ? name[2] : null;
                newAdvFieldsArray.push(fieldToModify);

                objToAdd[propertyName] = true;
              }
            });
          }
          newFieldsArray.push(field);
        }
        objToAdd[field.field_id] = true;
        objToAdd[field.advanced] = activeTab === tabs[1] ? 1 : 0;
      });
      setFormArray(newFieldsArray);
      setAdvancedFormArray(newAdvFieldsArray);
      setItem(objToAdd);
    }

    setCheckAll((prev) => !prev);
  };

  const handleFieldChange = (value, id, index) => {
    if (activeTab === tabs[0]) {
      let field = formArray.filter((param) => param.field_id === id)[0];
      field.newValue = value;
      field.advanced = 1;

      formArray.splice(index, 1, field);
    } else {
      let field = advancedFormArray.filter(
        (param) => param.viewLabel === id
      )[0];
      field.newValue = value;
      field.advanced = 0;
      advancedFormArray.splice(index, 1, field);
    }
  };

  const saveLoading = () => {
    setLoading(false);
  };

  const fetchMapper = () => {
    getMapper(activeTab);
  };

  // const changeHideButton = val => setHideButton(val);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formsToMap.length < 1) {
      toast.notify("Select One or More Fields", { type: "info" });
      return;
    } else {
      let arrayToSend = [];
      formsToMap.forEach((form, i) => {
        let newObj = {
          ...form,
          name: form.newValue
            ? form.newValue
            : form.name
            ? form.name
            : form.label,
        };
        if (newObj.newValue) {
          delete newObj.newValue;
        }
        if (newObj.config) {
          delete newObj.config;
        }
        if (newObj.options) {
          delete newObj.options;
        }
        if (activeTab === tabs[1]) {
          newObj.sub_type = form.sub_type;
          delete newObj.viewLabel;
        }

        //  newObj.child_type = newObj.default_child_type;
        arrayToSend.push(newObj);
      });

      try {
        setLoading(true);

        let dataToSend = {
          app_id: selectedApp.app_id,
          org_id: Number(selectedApp.org_id),
          fields: arrayToSend,
          force_delete: deleteFromPodio,
          push_data: pushData,
          table_name: selectedApp.app,
          space_id: selectedApp.space_id,
          space_name: selectedApp.full_app_object.hooks
            ? selectedApp.full_app_object.hooks[0].space_name
            : selectedApp.space_name,
          app_name: selectedApp.app,
          //todo: Add drivers to type
          credential_ids: [connectorId],
        };

        // dispatch(CreateMapper(dataToSend, saveLoading, fetchMapper, changeHideButton));
        const url = `${process.env.REACT_APP_BASEURL}/create_table`;
        const res = await Axios.post(url, dataToSend);
        if (isConnector) {
          // log
          addMappers(res.data.data);
        }
        saveLoading();
        fetchMapper();
        // changeHideButton(true)
        toast.notify("Mapper has been saved successfully");
      } catch (error) {
        saveLoading();
        console.error(error);
        // changeHideButton(false)
        // toast.notify(
        //   error.response.message,
        //   {
        //      type: "error"
        //   }
        // );
      }
    }
  };

  const handleDelete = async (e, field, activeTab = null) => {
    e.preventDefault();

    let fieldObj = {
      field_id: field.field_id,
      type: field.type,
      external_id: field.external_id,
      name: field.name,
    };

    if (activeTab === "Basic") {
      fieldObj.sub_type = field.default_sub_type;
      fieldObj.child_type = field.default_child_type;
      fieldObj.advanced = 0;
    } else {
      const selectedOption = getSelectedOptionData(
        lastWord(field.viewLabel),
        field.options
      );

      fieldObj.sub_type = selectedOption.sub_type;
      fieldObj.child_type = selectedOption.child_type;
      fieldObj.advanced = 1;
    }
    let loaderObj = {
      id: field.field_id,
      loading: true,
    };
    if (activeTab === tabs[1]) {
      loaderObj.id = `${field.field_id},${
        field.sub_type || field.default_sub_type
      },${field.child_type || field.default_child_type}`;
    }
    let fields = [fieldObj];
    try {
      let dataToSend = {
        app_id: selectedApp.app_id,
        fields: fields,
        credential_ids: [
          selectedApp.credential_id || selectedMapper.credential_id,
        ],
      };
      if (activeTab === tabs[1]) {
        dataToSend.sub_type = field.sub_type;
      }

      setActionLoader(loaderObj);
      const res = await Axios.post(
        `${process.env.REACT_APP_BASEURL}/drop_column`,
        dataToSend
      );
      setItem({});
      setFormArray([]);
      setAdvancedFormArray([]);
      getMapper(activeTab);
      toast.notify(res.message);
    } catch (e) {
      toast.notify(e?.response?.data?.error || "An Error occured", {
        type: "error",
      });
    } finally {
      setLoading(false);
      setActionLoader({
        id: "",
        loading: false,
      });
    }
  };

  const lastWord = (words) => {
    var n = words.split("|");
    return n[n.length - 1];
  };

  const handleAdd = async (e, field, activeTab = null) => {
    e.preventDefault();
    let fieldObj = {
      field_id: field.field_id,
      name: field.newValue
        ? field.newValue
        : field.name
        ? field.name
        : field.label,
      label: field.newValue
        ? field.newValue
        : field.name
        ? field.name
        : field.label,
      status: field.status,
      external_id: field.external_id,
      type: field.type,
    };

    if (activeTab === "Basic") {
      fieldObj.sub_type = field.default_sub_type;
      fieldObj.child_type = field.default_child_type;
      fieldObj.advanced = 0;
    } else {
      const selectedOption = getSelectedOptionData(
        lastWord(field.viewLabel),
        field.options
      );

      fieldObj.sub_type = selectedOption.sub_type;
      fieldObj.child_type = selectedOption.child_type;
      fieldObj.advanced = 1;
    }

    let loaderObj = {
      id: field.field_id,
      loading: true,
    };
    if (activeTab === tabs[1]) {
      loaderObj.id = `${field.field_id},${
        field.sub_type || field.default_sub_type
      },${field.child_type || field.default_child_type}`;
    }
    let fields = [fieldObj];
    try {
      let dataToSend = {
        app_id: selectedApp.app_id,
        fields: fields,
        credential_ids: [
          selectedApp.credential_id || selectedMapper.credential_id,
        ],
      };
      setActionLoader(loaderObj);
      await Axios.post(
        `${process.env.REACT_APP_BASEURL}/add_column`,
        dataToSend
      );
      setItem({});
      setFormArray([]);
      setAdvancedFormArray([]);
      getMapper(activeTab);
   
      toast.notify("Column added successfully");
    } catch (e) {
      toast.notify(e?.response?.data?.error || "An Error occured", {
        type: "error",
      });
    } finally {
      setLoading(false);
      setActionLoader({
        id: "",
        loading: false,
      });
    }
  };

  // This function takes in a selected field and uses the sub type to
  // get the child type from the options array ,it returns the selected option
  const getSelectedOptionData = (subtype, options) => {
    const selectedOption = options.find((option) => {
      if (option.child_type) {
        return option.child_type === subtype;
      }
      return option.sub_type === subtype;
    });
    return selectedOption;
  };
  const actionView = (field) => {
    return (
      <>
        {activeTab === tabs[0] ? (
          <TableCell>
            {fetchedData.includes(field.field_id) ? (
              <>
                {actionLoader.id === field.field_id && actionLoader.loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <RenderByPermission allowTeamMemberAccess={false}>
                      <span onClick={(e) => handleDelete(e, field, activeTab)}>
                        <i
                          className="fa fa-trash "
                          style={{
                            fontSize: "1.50em",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          title="Delete"
                        ></i>
                      </span>
                    </RenderByPermission>
                  </>
                )}
              </>
            ) : (
              <>
                {actionLoader.id === field.field_id && actionLoader.loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <span onClick={(e) => handleAdd(e, field, activeTab)}>
                    <i
                      className="fa fa-plus"
                      style={{
                        fontSize: "1.50em",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      title="Add"
                    ></i>
                  </span>
                )}
              </>
            )}
          </TableCell>
        ) : (
          <TableCell>
            {fetchedSubTypes.includes(
              `${field.field_id},${field.sub_type || field.default_sub_type},${
                field.child_type || field.default_child_type
              }`
            ) ? (
              <>
                {actionLoader.id ===
                  `${field.field_id},${
                    field.sub_type || field.default_sub_type
                  },${field.child_type || field.default_child_type}` &&
                actionLoader.loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <RenderByPermission allowTeamMemberAccess={false}>
                      <span onClick={(e) => handleDelete(e, field, activeTab)}>
                        <i
                          data-action-id={`${actionLoader}`}
                          className="fa fa-trash "
                          style={{
                            fontSize: "1.50em",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          title="Delete"
                        ></i>
                      </span>
                    </RenderByPermission>
                  </>
                )}
              </>
            ) : (
              <>
                {actionLoader.id ===
                  `${field.field_id},${
                    field.sub_type || field.default_sub_type
                  },${field.child_type || field.default_child_type}` &&
                actionLoader.loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <span onClick={(e) => handleAdd(e, field, activeTab)}>
                    <i
                      data-action-id={actionLoader.id}
                      className="fa fa-plus"
                      style={{
                        fontSize: "1.50em",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      title="Add"
                    ></i>
                  </span>
                )}
              </>
            )}
          </TableCell>
        )}
      </>
    );
  };

  return (
    <>
      <CredentialModal
        openModal={open}
        handleClose={closeCredentialModal}
        connector={selectedConnector}
        changeConnector={changeSelectedConncector}
        connectors={connectors}
        saveMapper={handleSubmit}
      />

      {isMobile ? (
        <Dialog
          open={openItemModal}
          onClose={handleCloseItemModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="connector-modal"
          fullWidth={true}
          // maxWidth="md"
          disableBackdropClick={false}
          disableEscapeKeyDown={true}
        >
          {connectors.length > 0 ? (
            <DialogContent
              style={{
                width: "85vw",
              }}
            >
              {selectApps ? (
                <Box>
                  <Typography variant="h6">
                    <div
                      style={{ paddingBottom: "3%" }}
                      className={classes.modalHeader}
                    >
                      <img
                        src={mapperBack}
                        alt="back"
                        onClick={handleSelectedAppClick}
                      />

                      <div>
                        <img
                          onClick={handleCloseItemModal}
                          alt="cancel"
                          src={cancel}
                        />
                      </div>
                    </div>
                  </Typography>

                  {fetchingData ? (
                    <div className="progress-div">
                      <CircularProgress size={54} />
                    </div>
                  ) : (
                    <Box style={{ backgroundColor: "#FFFDF8" }} item xs={8}>
                      <Box className="box">
                        <Table
                          className="mapper_grid_table"
                          style={{ backgroundColor: "white" }}
                        >
                          <TableHead>
                            <TableRow style={{ height: "3rem" }}>
                              {[
                                "Field Name",
                                "Field ID",
                                "External ID",
                                "Label",
                              ].map((header) => (
                                <TableCell xs={"auto"} key={header} item>
                                  {header}
                                </TableCell>
                              ))}
                              {hideButton && (
                                <TableCell xs={"auto"} item>
                                  Action
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formsToMap.map((field, i) => (
                              <TableRow
                                style={{ height: "5rem" }}
                                key={activeTab === tabs[0] ? field.field_id : i}
                              >
                                <TableCell component="th" scope="row">
                                  {activeTab === tabs[0]
                                    ? field.label
                                    : field.viewLabel}
                                </TableCell>
                                <TableCell>{field.field_id}</TableCell>
                                <TableCell>{field.external_id}</TableCell>
                                <TableCell style={{ minWidth: "200px" }}>
                                  {/* <FormControl data-id={field.field_id} defaultValue={field.label} /> */}
                                  <MappersInput
                                    field={field}
                                    action={(e) => {
                                    
                                      handleFieldChange(
                                        e.target.value,
                                        activeTab === tabs[0]
                                          ? field.field_id
                                          : field.viewLabel,
                                        i
                                      );
                                    }}
                                  />
                                  {/* {field.external_id} */}
                                </TableCell>

                                {hideButton && actionView(field)}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                      <DialogActions
                        className={!hideButton ? "justify-content-between" : ""}
                        style={{
                          position: "absolute",
                          bottom: "0",
                          background: "#ffffff",
                          right: "0",
                          left: "0",
                          justifyContent: "space-between",
                          margin: "0 5% 3% 5%",
                        }}
                      >
                        <div>
                          {props?.type === "new" ? (
                            <div
                              className={
                                "d-inline push-tooltip position-relative "
                              }
                            >
                              <FormControlLabel
                                control={<Checkbox onChange={updatePushData} />}
                                label={`Push Data`}
                                style={{ color: "#DE911D" }}
                              ></FormControlLabel>
                              <span className="tooltiptext">
                                Push existing data into database upon creation
                              </span>
                            </div>
                          ) : null}

                          <div
                            className={
                              "d-inline push-tooltip position-relative "
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={updateDeleteFromPodio}
                                  checked={deleteFromPodio === 1}
                                />
                              }
                              label={`Delete From Podio`}
                              style={{ color: "#E12D39" }}
                            ></FormControlLabel>
                            <span className="tooltiptext">
                              Enable this option if you want items deleted from
                              Podio to be deleted from your Mysql instance. If
                              not enabled, when an item is deleted from Podio,
                              it will be marked as deleted in your Mysql
                              instance.
                            </span>
                          </div>
                        </div>
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <>
                            {!hideButton && (
                              <div className={"mapModalFooter"}>
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  className="map-data-button"
                                  style={{
                                    width: "146px",
                                    height: "35px",
                                    fontSize: "14px",
                                    padding: "0",
                                  }}
                                >
                                  Create Mapper
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </DialogActions>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography variant="h6">
                    <div className={classes.modalHeader}>
                      <p className={classes.headerText}>Create Mapper</p>
                      <div>
                        <img
                          onClick={handleCloseItemModal}
                          alt="cancel"
                          src={cancel}
                        />
                      </div>
                    </div>
                  </Typography>

                  {fetchingData ? (
                    <div className="progress-div">
                      {/* <Loader /> */}
                      <CircularProgress size={54} />
                    </div>
                  ) : (
                    <form>
                      <DialogContent
                        id="alert-dialog-description"
                        style={{
                          marginTop: "0",
                          marginBottom: "2rem",
                          padding: "0",
                        }}
                      >
                        <Box container>
                          <Box item xs={4} sx={{ borderRight: 1 }}>
                            <div className="mb-3 tabM">
                              {tabs.map((tab, i) => (
                                <Grid
                                  item
                                  key={i}
                                  xs={6}
                                  data-name={tab}
                                  onClick={toggleTab}
                                  className={`${
                                    activeTab === tabs[0] && tab === tabs[0]
                                      ? "active-leftM"
                                      : activeTab === tabs[1] && tab === tabs[1]
                                      ? "active-rightM"
                                      : ""
                                  } text-center p-2`}
                                >
                                  {tab}
                                </Grid>
                              ))}
                            </div>
                            <Box className="left-boxM boxM">
                              <Typography
                                style={{ color: "#DE911D" }}
                                className="my-3 d-flex align-items-center"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      // disabled={fetchedData.includes(field.field_id)}
                                      checked={checkAll}
                                      onChange={handleCheckAll}
                                      // name={field.field_id}
                                      style={{
                                        color: "#DE911D",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  }
                                />
                                List Fields
                              </Typography>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  {fielsdToMap.map(
                                    (field, i) =>
                                      field.status !== "deleted" && (
                                        <React.Fragment key={i}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                disabled={
                                                  activeTab === tabs[0]
                                                    ? fetchedData.includes(
                                                        field.field_id
                                                      )
                                                    : true
                                                }
                                                checked={
                                                  item[field.field_id] === true
                                                }
                                                onChange={handleChange}
                                                name={`${field.field_id}`}
                                              />
                                            }
                                            label={`${field.label} ${
                                              field.type === "app" ? "Ⓖ" : ""
                                            } ${
                                              field.type === "calculation"
                                                ? "©"
                                                : ""
                                            }`}
                                          />
                                          {activeTab === tabs[1] &&
                                            field.options &&
                                            field.options.map((option, i) => (
                                              <>
                                                {field.type === "text" &&
                                                  field.status !==
                                                    "deleted" && (
                                                    // field.config.settings.size === "small" &&
                                                    // option.sub_type === "text" &&
                                                    <FormControlLabel
                                                      className="ml-4"
                                                      key={i}
                                                      control={
                                                        <Checkbox
                                                          v-if
                                                          className={
                                                            "test-class"
                                                          }
                                                          disabled={fetchedData.includes(
                                                            `${
                                                              field.field_id
                                                            },${
                                                              option.sub_type ||
                                                              option.default_sub_type
                                                            },${
                                                              option.child_type ||
                                                              option.default_child_type
                                                            }`
                                                          )}
                                                          checked={
                                                            item[
                                                              `${
                                                                field.field_id
                                                              },${
                                                                option.sub_type ||
                                                                option.default_sub_type
                                                              }`.concat(
                                                                !option.child_type &&
                                                                  !option.default_child_type
                                                                  ? ""
                                                                  : `,${
                                                                      option.child_type ||
                                                                      option.default_child_type
                                                                    }`
                                                              )
                                                            ] === true
                                                          }
                                                          onChange={
                                                            handleChangeOptions
                                                          }
                                                          name={[
                                                            field.field_id,
                                                            option.sub_type,
                                                            ...(!!option.child_type
                                                              ? [
                                                                  option.child_type,
                                                                ]
                                                              : []),
                                                          ]}
                                                        />
                                                      }
                                                      label={
                                                        !option.child_type
                                                          ? `${field.label}|${option.sub_type}`
                                                          : `${option.sub_type}|${option.child_type}`
                                                      }
                                                    />
                                                  )}
                                                {field.type === "text" &&
                                                  field.status !== "deleted" &&
                                                  field.config.settings.size ===
                                                    "large" &&
                                                  option.sub_type ===
                                                    "textarea" && (
                                                    <FormControlLabel
                                                      className="ml-4"
                                                      key={i}
                                                      control={
                                                        <Checkbox
                                                          v-if
                                                          className={
                                                            "test-class"
                                                          }
                                                          disabled={fetchedData.includes(
                                                            `${
                                                              field.field_id
                                                            },${
                                                              option.sub_type ||
                                                              option.default_sub_type
                                                            },${
                                                              option.child_type ||
                                                              option.default_child_type
                                                            }`
                                                          )}
                                                          checked={
                                                            item[
                                                              `${
                                                                field.field_id
                                                              },${
                                                                option.sub_type ||
                                                                option.default_sub_type
                                                              }`.concat(
                                                                !option.child_type &&
                                                                  !option.default_child_type
                                                                  ? ""
                                                                  : `,${
                                                                      option.child_type ||
                                                                      option.default_child_type
                                                                    }`
                                                              )
                                                            ] === true
                                                          }
                                                          onChange={
                                                            handleChangeOptions
                                                          }
                                                          name={[
                                                            field.field_id,
                                                            option.sub_type,
                                                            ...(!!option.child_type
                                                              ? [
                                                                  option.child_type,
                                                                ]
                                                              : []),
                                                          ]}
                                                        />
                                                      }
                                                      label={
                                                        !option.child_type
                                                          ? `${field.label}|${option.sub_type}`
                                                          : `${option.sub_type}|${option.child_type}`
                                                      }
                                                    />
                                                  )}

                                                {field.type !== "text" &&
                                                  field.status !==
                                                    "deleted" && (
                                                    <FormControlLabel
                                                      className="ml-4"
                                                      key={i}
                                                      control={
                                                        <Checkbox
                                                          v-if
                                                          className={
                                                            "test-class"
                                                          }
                                                          disabled={fetchedData.includes(
                                                            `${
                                                              field.field_id
                                                            },${
                                                              option.sub_type ||
                                                              option.default_sub_type
                                                            },${
                                                              option.child_type ||
                                                              option.default_child_type
                                                            }`
                                                          )}
                                                          checked={
                                                            item[
                                                              `${
                                                                field.field_id
                                                              },${
                                                                option.sub_type ||
                                                                option.default_sub_type
                                                              }`.concat(
                                                                !option.child_type &&
                                                                  !option.default_child_type
                                                                  ? ""
                                                                  : `,${
                                                                      option.child_type ||
                                                                      option.default_child_type
                                                                    }`
                                                              )
                                                            ] === true
                                                          }
                                                          onChange={
                                                            handleChangeOptions
                                                          }
                                                          name={[
                                                            field.field_id,
                                                            option.sub_type,
                                                            ...(!!option.child_type
                                                              ? [
                                                                  option.child_type,
                                                                ]
                                                              : []),
                                                          ]}
                                                        />
                                                      }
                                                      label={
                                                        !option.child_type
                                                          ? option.sub_type
                                                          : `${option.sub_type}|${option.child_type}`
                                                      }
                                                    />
                                                  )}
                                              </>
                                            ))}
                                        </React.Fragment>
                                      )
                                  )}
                                </FormGroup>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </DialogContent>

                      <DialogActions
                        className={!hideButton ? "justify-content-between" : ""}
                        style={{
                          position: "absolute",
                          bottom: "0",
                          background: "#ffffff",
                          right: "0",
                          left: "0",
                          justifyContent: "space-between",
                          margin: "0 5% 3% 5%",
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <>
                            {!hideButton && (
                              <div
                                style={{ width: "100%" }}
                                className={"mapModalFooter"}
                              >
                                <button
                                  type="button"
                                  onClick={handleSelectedAppClick}
                                  className="map-data-button w-100"
                                >
                                  Apply
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </DialogActions>
                    </form>
                  )}
                </Box>
              )}
            </DialogContent>
          ) : (
            <Error
              errorMsg="No Connection Found"
              errorAction="Setup MySQL Syncing"
              onClick={() => {
                history.push("/connector");
              }}
            />
          )}
        </Dialog>
      ) : (
        <Dialog
          open={openItemModal}
          onClose={handleCloseItemModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="connector-modal"
          fullWidth={true}
          disableBackdropClick={false}
          disableEscapeKeyDown={true}
        >
          {connectors.length > 0 ? (
            <DialogContent>
              <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
                <h5>Mapper Connection</h5>
                <img src={cancel} alt="cancel" onClick={handleCloseItemModal} />
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className="tabE">
                    {tabs.map((tab, i) => (
                      <Grid
                        item
                        key={i}
                        xs={6}
                        data-name={tab}
                        onClick={toggleTab}
                        className={`${
                          activeTab === tabs[0] && tab === tabs[0]
                            ? "active-leftE"
                            : activeTab === tabs[1] && tab === tabs[1]
                            ? "active-rightE"
                            : ""
                        } text-center`}
                      >
                        {tab}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              {fetchingData ? (
                <div className="progress-div">
                  {/* <Loader /> */}
                  <CircularProgress size={54} />
                </div>
              ) : (
                <form>
                  <DialogContent
                    id="alert-dialog-description"
                    style={{ marginBottom: "4rem" }}
                  >
                    <Grid container>
                      <Grid item xs={3} sx={{ borderRight: 1 }}>
                        <Box className="left-box box">
                          <Typography className="my-3 d-flex justify-content-between align-items-center">
                            List Fields
                            <FormControlLabel
                              control={
                                <Checkbox
                                  // disabled={fetchedData.includes(field.field_id)}
                                  checked={checkAll}
                                  onChange={handleCheckAll}
                                  // name={field.field_id}
                                />
                              }
                            />
                          </Typography>
                          <FormControl
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {fielsdToMap.map(
                                (field, i) =>
                                  field.status !== "deleted" && (
                                    <React.Fragment key={i}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disabled={
                                              activeTab === tabs[0]
                                                ? fetchedData.includes(
                                                    field.field_id
                                                  )
                                                : true
                                            }
                                            checked={
                                              item[field.field_id] === true
                                            }
                                            onChange={handleChange}
                                            name={`${field.field_id}`}
                                          />
                                        }
                                        label={`${field.label} ${
                                          field.type === "app" ? "Ⓖ" : ""
                                        } ${
                                          field.type === "calculation"
                                            ? "©"
                                            : ""
                                        }`}
                                      />
                                      {activeTab === tabs[1] &&
                                        field.options &&
                                        field.options.map((option, i) => (
                                          <>
                                            {field.type === "text" &&
                                              field.status !== "deleted" && (
                                                // field.config.settings.size === "small" &&
                                                // option.sub_type === "text" &&
                                                <FormControlLabel
                                                  className="ml-4"
                                                  key={i}
                                                  control={
                                                    <Checkbox
                                                      v-if
                                                      className={"test-class"}
                                                      disabled={fetchedData.includes(
                                                        `${field.field_id},${
                                                          option.sub_type ||
                                                          option.default_sub_type
                                                        },${
                                                          option.child_type ||
                                                          option.default_child_type
                                                        }`
                                                      )}
                                                      checked={
                                                        item[
                                                          `${field.field_id},${
                                                            option.sub_type ||
                                                            option.default_sub_type
                                                          }`.concat(
                                                            !option.child_type &&
                                                              !option.default_child_type
                                                              ? ""
                                                              : `,${
                                                                  option.child_type ||
                                                                  option.default_child_type
                                                                }`
                                                          )
                                                        ] === true
                                                      }
                                                      onChange={
                                                        handleChangeOptions
                                                      }
                                                      name={[
                                                        field.field_id,
                                                        option.sub_type,
                                                        ...(!!option.child_type
                                                          ? [option.child_type]
                                                          : []),
                                                      ]}
                                                    />
                                                  }
                                                  label={
                                                    !option.child_type
                                                      ? `${field.label}|${option.sub_type}`
                                                      : `${option.sub_type}|${option.child_type}`
                                                  }
                                                />
                                              )}
                                            {field.type === "text" &&
                                              field.status !== "deleted" &&
                                              field.config.settings.size ===
                                                "large" &&
                                              option.sub_type ===
                                                "textarea" && (
                                                <FormControlLabel
                                                  className="ml-4"
                                                  key={i}
                                                  control={
                                                    <Checkbox
                                                      v-if
                                                      className={"test-class"}
                                                      disabled={fetchedData.includes(
                                                        `${field.field_id},${
                                                          option.sub_type ||
                                                          option.default_sub_type
                                                        },${
                                                          option.child_type ||
                                                          option.default_child_type
                                                        }`
                                                      )}
                                                      checked={
                                                        item[
                                                          `${field.field_id},${
                                                            option.sub_type ||
                                                            option.default_sub_type
                                                          }`.concat(
                                                            !option.child_type &&
                                                              !option.default_child_type
                                                              ? ""
                                                              : `,${
                                                                  option.child_type ||
                                                                  option.default_child_type
                                                                }`
                                                          )
                                                        ] === true
                                                      }
                                                      onChange={
                                                        handleChangeOptions
                                                      }
                                                      name={[
                                                        field.field_id,
                                                        option.sub_type,
                                                        ...(!!option.child_type
                                                          ? [option.child_type]
                                                          : []),
                                                      ]}
                                                    />
                                                  }
                                                  label={
                                                    !option.child_type
                                                      ? `${field.label}|${option.sub_type}`
                                                      : `${option.sub_type}|${option.child_type}`
                                                  }
                                                />
                                              )}

                                            {field.type !== "text" &&
                                              field.status !== "deleted" && (
                                                <FormControlLabel
                                                  className="ml-4"
                                                  key={i}
                                                  control={
                                                    <Checkbox
                                                      v-if
                                                      className={"test-class"}
                                                      disabled={fetchedData.includes(
                                                        `${field.field_id},${
                                                          option.sub_type ||
                                                          option.default_sub_type
                                                        },${
                                                          option.child_type ||
                                                          option.default_child_type
                                                        }`
                                                      )}
                                                      checked={
                                                        item[
                                                          `${field.field_id},${
                                                            option.sub_type ||
                                                            option.default_sub_type
                                                          }`.concat(
                                                            !option.child_type &&
                                                              !option.default_child_type
                                                              ? ""
                                                              : `,${
                                                                  option.child_type ||
                                                                  option.default_child_type
                                                                }`
                                                          )
                                                        ] === true
                                                      }
                                                      onChange={
                                                        handleChangeOptions
                                                      }
                                                      name={[
                                                        field.field_id,
                                                        option.sub_type,
                                                        ...(!!option.child_type
                                                          ? [option.child_type]
                                                          : []),
                                                      ]}
                                                    />
                                                  }
                                                  label={
                                                    !option.child_type
                                                      ? option.sub_type
                                                      : `${option.sub_type}|${option.child_type}`
                                                  }
                                                />
                                              )}
                                          </>
                                        ))}
                                    </React.Fragment>
                                  )
                              )}
                            </FormGroup>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={9}>
                        <Box className="box">
                          <Table>
                            <TableHead>
                              <TableRow>
                                {[
                                  "Field Name",
                                  "Field ID",
                                  "External ID",
                                  "Label",
                                ].map((header) => (
                                  <TableCell xs={"auto"} key={header} item>
                                    {header}
                                  </TableCell>
                                ))}
                                {hideButton && (
                                  <TableCell xs={"auto"} item>
                                    Action
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {formsToMap.map((field, i) => (
                                <TableRow
                                  key={
                                    activeTab === tabs[0] ? field.field_id : i
                                  }
                                >
                                  <TableCell component="th" scope="row">
                                    {activeTab === tabs[0]
                                      ? field.label
                                      : field.viewLabel}
                                  </TableCell>
                                  <TableCell>{field.field_id}</TableCell>
                                  <TableCell>{field.external_id}</TableCell>
                                  <TableCell style={{ minWidth: "200px" }}>
                                    {/* <FormControl data-id={field.field_id} defaultValue={field.label} /> */}
                                    <MappersInput
                                      field={field}
                                      action={(e) => {
                                        
                                        handleFieldChange(
                                          e.target.value,
                                          activeTab === tabs[0]
                                            ? field.field_id
                                            : field.viewLabel,
                                          i
                                        );
                                      }}
                                    />
                                    {/* {field.external_id} */}
                                  </TableCell>

                                  {hideButton && actionView(field)}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions
                    className={!hideButton ? "justify-content-between" : ""}
                    style={{
                      position: "absolute",
                      bottom: "0",
                      background: "#ffffff",
                      right: "0",
                      left: "0",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Button
                    id="button-cancel"
                    disabled={loading}
                    onClick={handleCloseItemModal}
                    color="primary"
                    autoFocus
                  >
                    Close
                  </Button> */}
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        {!hideButton && (
                          <div className={"mapModalFooter"}>
                            {/* {
                            connector?.mysql_status === true && connector?.pgsql_status === true ?
                              <div className="mapModalFooter_dbs">
                                {
                                  connector?.mysql_status === true ?
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={addorRemoveDrivers}
                                          value={"mysql"}
                                          name={"mysql"}
                                        />
                                      }
                                      label={`MySql`}
                                      title="MYSQL database to store mapper"
                                    ></FormControlLabel> : null
                                }
                                {
                                  connector?.pgsql_status === true ?
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={addorRemoveDrivers}
                                          value={"pgsql"}
                                          name={"pgsql"}
                                        />
                                      }
                                      label={`Postgrs`}
                                      title="PGSQL database to store mapper"
                                    ></FormControlLabel> : null
                                }

                              </div> : null
                          } */}

                            {props?.type === "new" ? (
                              <div
                                className={
                                  "d-inline push-tooltip position-relative "
                                }
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={updatePushData}
                                      // name={field.field_id}
                                    />
                                  }
                                  label={`Push Data`}
                                ></FormControlLabel>
                                <span className="tooltiptext">
                                  Push existing data into database upon creation
                                </span>
                              </div>
                            ) : null}

                            <div
                              className={
                                "d-inline push-tooltip position-relative "
                              }
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={updateDeleteFromPodio}
                                    checked={deleteFromPodio === 1}
                                    // name={field.field_id}
                                  />
                                }
                                label={`Delete From Podio`}
                              ></FormControlLabel>
                              <span className="tooltiptext">
                                Enable this option if you want items deleted
                                from Podio to be deleted from your Mysql
                                instance. If not enabled, when an item is
                                deleted from Podio, it will be marked as deleted
                                in your Mysql instance.
                              </span>
                            </div>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="map-data-button"
                            >
                              Save Mapper
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </DialogActions>
                </form>
              )}
            </DialogContent>
          ) : (
            <Error
              errorMsg="No Connection Found"
              errorAction="Setup MySQL Syncing"
              onClick={() => {
                history.push("/connector");
              }}
            />
          )}
        </Dialog>
      )}
    </>
  );
};
export default CreateNewDataMapModalV2;
