import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import $ from "jquery";
import "./Card.css";
import { saveCard, initiateSub } from "../../services/sync";
import { toast } from "../../helpers/apiRequests";
import { getMe } from "../../services/auth";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    "@media (max-width: 600px)": {
      width: "90% !important",
    },
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
}));

function changeImpactedElement(tagId, removeClass, addClass) {
  removeClass = removeClass || "";
  addClass = addClass || "";
  $("[data-bluesnap=" + tagId + "]")
    .removeClass(removeClass)
    .addClass(addClass);
}

const bluesnapSetUp = (bluesnapToken) => {
  const script = document.createElement("script");
  script.src = "https://bluesnap.com/web-sdk/4/bluesnap.js";

  // cardUrl: object that stores card type code (received from BlueSnap) and associated card image URL
  const cardUrl = {
    AMEX: "https://files.readme.io/97e7acc-Amex.png",
    DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
    DISCOVER: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
    VISA: "https://files.readme.io/9018c4f-Visa.png",
  };

  const bsObj = {
    //insert your Hosted Payment Fields token
    token: bluesnapToken,
    onFieldEventHandler: {
      onFocus: function (tagId) {
        // Handle focus
        changeImpactedElement(tagId, "", "hosted-field-focus");
      },
      onBlur: function (tagId) {
        // Handle blur
        changeImpactedElement(tagId, "hosted-field-focus");
      },
      onError: function (tagId, errorCode, errorDescription) {
        // Handle a change in validation by displaying help text
        $("#" + tagId + "-help")
          .removeClass("helper-text-green")
          .text(errorDescription);
      },
      onType: function (tagId, cardType, cardData) {
        // get card type from cardType and display card image
        $("#card-logo > img").attr("src", cardUrl[cardType]);
        if (null != cardData) {
          $("#" + tagId + "-help")
            .addClass("helper-text-green")
            .text("Okay.");
        }
      },
      onValid: function (tagId) {
        // Handle a change in validation by removing any help text
        $("#" + tagId + "-help").text("");
      },
    },
    style: {
      // Styling all inputs
      input: {
        "font-size": "14px",
        "font-family":
          "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
        "line-height": "1.42857143",
        color: "#555",
      },
      // Styling input state
      ":focus": {
        color: "#555",
      },
    },
    ccnPlaceHolder: "1234 5678 9012 3456",
    cvvPlaceHolder: "123",
    expPlaceHolder: "MM / YY",
  };
  script.onload = () => {
    window.bluesnap.hostedPaymentFieldsCreate(bsObj);
  };
  document.body.appendChild(script);
};

const AddCardComponent = (props) => {
  const classes = useStyles();
  const [bluesnapToken, setBlueSnapToken] = useState(props.bluesnapToken);
  const history = useHistory();

  const initiateSubscription = async () => {
    try {
      let payload = {
        plan_id: props.plainId,
      };
      await initiateSub(payload);
      history.push("/dashboard/sync");
    } catch (e) {
      props.setIsloading(false);
    }
  };

  /* Calling bluesnap.submitCredentials: function that submits card data to
  BlueSnap and calls input function with card data object if submission was successful */
  function submitCard(e) {
    e.preventDefault();

    window.bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
      const cardData = callback.cardData;
      const name = $("#cardholder-name").val();
      const payload = {
        name,
        email: props.user.email,
        bluesnap_token: bluesnapToken,
        last_four_digit: cardData.last4Digits,
        card_brand: cardData.ccType,
        card_expire: cardData.exp,
      };

      if (
        !name ||
        props.user.email === "" ||
        cardData.last4Digits === "" ||
        cardData.ccType === "" ||
        cardData.exp === ""
      ) {
        toast.notify("Please enter your card details");
        props.setIsloading(false);
      }

      if (null != callback.error) {
        const errorArray = callback.error;
        for (const i in errorArray) {
          $("#" + errorArray[i].tagId + "-help").text(
            errorArray[i].errorCode + " - " + errorArray[i].errorDescription
          );
        }
      } else {
        props.setIsloading(true);
        saveCard(payload)
          .then((res) => {
            const response = res;
            if (response.message) {
              toast.notify("Card saved successfully!");
            }
            initiateSubscription();
            props.setIsloading(false);
          })
          .catch((error) => {
            toast("Failed to add card!");
            props.setIsloading(false);
          });
        props.setIsloading(true);
      }
    });
  }

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setBlueSnapToken(me.data.bluesnap_token);
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  React.useEffect(() => {
    bluesnapSetUp(bluesnapToken);
  }, [bluesnapToken]);

  return (
    <>
      <div className={classes.paper}>
        <div className="card-name mt-3">
          <label className="mb-2" htmlFor="name">
            Card Holder
          </label>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="cardholder-name"
            placeholder="Full Name"
            name="name"
            autoComplete="name"
          />
        </div>

        <div className="card-number">
          <div className="div-inner">
            <label className="mb-2" htmlFor="cvv">
              Card Number
            </label>
            <div
              className="hosted-textfield div-inner"
              id="card-number"
              data-bluesnap="ccn"
            />
            <span className="helper-text" id="ccn-help" />
          </div>

          <div id="card-logo" className="card-logo mt-3">
            <img
              src="https://files.readme.io/d1a25b4-generic-card.png"
              height="30px"
              alt="Sync"
            />
          </div>
        </div>

        <div className="card-extras">
          <div className="div-inner">
            <label className="mb-2" htmlFor="exp-date">
              Exp. (MM/YY)
            </label>
            <div
              className="hosted-textfield"
              id="exp-date"
              data-bluesnap="exp"
            />
            <span className="helper-text" id="exp-help" />
          </div>

          <div>
            <label className="mb-2" htmlFor="cvv">
              CVV
            </label>
            <div
              className="hosted-textfield"
              id="card-number"
              data-bluesnap="cvv"
            />
            <span className="helper-text" id="cvv-help" />
          </div>
        </div>

        {props.isLoading ? (
          <div
            style={{ float: "right" }}
            className="flex justify-content-end"
          >
            <button className="generate_btn">
              <CircularProgress sx={{ color: "white" }} size={20} />
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <button onClick={(e) => submitCard(e)} className="generate_btn">
              Activate Billing
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(AddCardComponent);
